import { Checkbox } from 'antd'
import styled from 'styled-components'
import { PropsTheme } from 'theme'

export const CheckboxStyled: any = styled(Checkbox).attrs((props: any) => ({
  fontSize: props.fontSize || props.theme.fontSizes.sm
}))`
  && {
    font-size: ${(props) => props.fontSize};
  }

  & .ant-checkbox {
    vertical-align: middle;
  }

  & .ant-checkbox-inner {
    border-radius: 4px;
  }
  margin-inline-start: 0px;

  & .ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${(props: PropsTheme) => props.theme.colors.main};
    background-color: ${(props: PropsTheme) => props.theme.colors.main};
  }

  & .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  & .ant-checkbox:hover .ant-checkbox-inner,
  & .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(props: PropsTheme) => props.theme.colors.main};
  }

  & .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
    border-color: ${(props: PropsTheme) => props.theme.colors.main} !important;

    ::after {
      border-color: ${(props: PropsTheme) => props.theme.colors.white};
    }
  }
`
