import { ReactNode } from 'react'
import { Typography } from 'antd'
import { ParagraphProps } from 'antd/es/typography/Paragraph'
import { TextProps } from 'antd/es/typography/Text'
import { TitleProps } from 'antd/es/typography/Title'
import cx from 'clsx'

const { Title, Text, Paragraph } = Typography

interface IText extends TextProps {
  text: string | ReactNode
  className?: string
}

export const AntText: React.FC<IText> = ({ text, className, ...rest }) => {
  const customClassName = cx('t-ant-typography-text', className)
  return (
    <Text {...(rest as any)} className={customClassName}>
      {text}
    </Text>
  )
}

interface ITitle extends TitleProps {
  text: string
  className?: string
}
export const AntTitle: React.FC<ITitle> = ({ text, className, ...rest }) => {
  const customClassName = cx('t-ant-typography-title', className)
  return (
    <Title {...(rest as any)} className={customClassName}>
      {text}
    </Title>
  )
}

interface IParagraph extends ParagraphProps {
  text: string
  className?: string
}
export const AntParagraph: React.FC<IParagraph> = ({ text, className, ...rest }) => {
  const customClassName = cx('t-ant-typography-paragraph', className)
  return (
    <Paragraph {...(rest as any)} className={customClassName}>
      {text}
    </Paragraph>
  )
}
