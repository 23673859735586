import React, { Fragment } from 'react'
import { MainButton } from 'components/Button'
import { OldRow } from 'components/Row'
import { MoonLoader, PropagateLoader } from 'react-spinners'
import styled from 'styled-components'
import { lightTheme } from 'theme'

import { ReloadOutlined } from '@ant-design/icons'

const ContainerSession = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ContainerPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LoadingSession = () => {
  return (
    <>
      <ContainerSession>
        <PropagateLoader color={lightTheme?.colors?.main} />
      </ContainerSession>
    </>
  )
}

export const LoadingPage = () => {
  return (
    <>
      <ContainerPage>
        <OldRow justify="center" style={{ marginTop: 160 }}>
          <MoonLoader size={40} color={lightTheme?.colors?.main} speedMultiplier={0.5} />
        </OldRow>
      </ContainerPage>
    </>
  )
}

export const LoadingWithReloadButton = ({ handleClick }: { handleClick: () => void }) => {
  return (
    <Fragment>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <MainButton onClick={handleClick} icon={<ReloadOutlined />}>
          โหลดช้า? กดเพื่อโหลดใหม่
        </MainButton>
      </div>
      <LoadingPage />
    </Fragment>
  )
}

export const LoadingCard = () => {
  return (
    <>
      <OldRow justify="center">
        <MoonLoader size={32} color={lightTheme?.colors?.main} speedMultiplier={0.5} />
      </OldRow>
    </>
  )
}
