import { Table, TableProps } from 'antd'
import { ErrorApi, ErrorNotFound } from 'components/Error'
// import DataGrid from 'react-data-grid'
import styled from 'styled-components'
import { PropsTheme } from 'theme'

export interface Props extends TableProps<any> {
  isError?: boolean
  icon?: JSX.Element
  errorMessage?: any | JSX.Element
  notFoundMessage?: string | JSX.Element
}

export const TableWithTemplateError: React.FC<Props> = ({
  isError = false,
  icon,
  errorMessage,
  notFoundMessage,
  ...rest
}) => {
  const ErrorByType = () => {
    switch (true) {
      case isError:
        return <ErrorApi icon={icon} message={errorMessage} />
      default:
        return <ErrorNotFound message={notFoundMessage} />
    }
  }
  // Todo: add row selection here...
  return <Table {...rest} locale={{ emptyText: <ErrorByType /> }} />
}

export const StyledExpandedTable = styled(TableWithTemplateError)`
  .ant-table-tbody > tr > td {
    padding: 24px 16px 24px 16px !important;
    border-bottom: 1px solid ${(props: PropsTheme) => props.theme.colors.mediumGrey};
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
  }
  .ant-table-thead > tr > th {
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
    font-weight: bold;
    color: ${(props: PropsTheme) => props.theme.fontColor.medium};
  }
  .ant-table-row-expand-icon-cell {
    width: 1px;
  }
`

export const StyledExportTableJT = styled(TableWithTemplateError)`
  .ant-table-thead > tr > th {
    border-top: 1px solid ${(props: PropsTheme) => props.theme.colors.mediumGrey};
    border-bottom: 1px solid ${(props: PropsTheme) => props.theme.colors.mediumGrey};
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
    font-weight: bold;
    color: ${(props: PropsTheme) => props.theme.fontColor.medium};
    background: ${(props: PropsTheme) => props.theme.colors.white};
  }
  .ant-table-tbody > tr > td {
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
    color: ${(props: PropsTheme) => props.theme.fontColor.dark};
    border-bottom: 1px solid ${(props: PropsTheme) => props.theme.colors.mediumGrey};
  }
`

export const StyledExportTableThaipost = styled(TableWithTemplateError)`
  .ant-table-thead > tr > th {
    border-top: 1px solid ${(props: PropsTheme) => props.theme.colors.mediumGrey};
    border-bottom: 1px solid ${(props: PropsTheme) => props.theme.colors.mediumGrey};
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
    color: ${(props: PropsTheme) => props.theme.fontColor.dark};
    background: ${(props: PropsTheme) => props.theme.colors.white};
  }
  .ant-table-tbody > tr > td {
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
    color: ${(props: PropsTheme) => props.theme.fontColor.dark};
    border-bottom: 1px solid ${(props: PropsTheme) => props.theme.colors.mediumGrey};
  }
`

export const StyledTable = styled(TableWithTemplateError)`
  & .ant-table-selection-extra {
    padding-inline-start: 8px;

    span {
      vertical-align: sub;
    }
  }

  & .ant-checkbox-inner {
    border-radius: 4px;
  }

  & .ant-checkbox-checked .ant-checkbox-inner,
  & .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${(props: PropsTheme) => props.theme.colors.main};
    border-color: ${(props: PropsTheme) => props.theme.colors.main};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(props: PropsTheme) => props.theme.colors.main};
  }

  & .ant-radio-checked .ant-radio-inner {
    border-color: ${(props: PropsTheme) => props.theme.colors.main};
  }

  & .ant-radio-checked .ant-radio-inner::after {
    background-color: ${(props: PropsTheme) => props.theme.colors.main};
  }

  & .ant-radio-wrapper:hover,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${(props: PropsTheme) => props.theme.colors.main};
  }

  .ant-table-cell a {
    color: ${(props: PropsTheme) => props.theme.colors.main};
  }

  .ant-table-thead > tr > th {
    background: ${(props: PropsTheme) => props.theme.colors.whiteOff};
    border-bottom: 1px solid ${(props: PropsTheme) => props.theme.colors.mediumGrey};
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
    font-weight: bold;
    color: ${(props: PropsTheme) => props.theme.fontColor.medium};
    height: 5px !important;
  }

  .ant-table-thead > tr > th ::before {
    height: 0px !important;
  }

  .ant-table-tbody > tr > td {
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
    border-bottom: 1px solid ${(props: PropsTheme) => props.theme.colors.mediumGrey};
  }

  .ant-table-column-sorter {
    margin-left: 8px;
  }

  .ant-table-footer {
    background: ${(props: PropsTheme) => props.theme.colors.white};
    border-bottom: 1px solid ${(props: PropsTheme) => props.theme.colors.mediumGrey};
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
    font-weight: bold;
    color: ${(props: PropsTheme) => props.theme.fontColor.medium};
  }
`

export const StyledTableLoading = styled(StyledTable)`
  .ant-table-thead > tr > th {
    height: 54px !important;
  }
`

export const StyledTableWithLargeText = styled(TableWithTemplateError)`
  .ant-table-thead > tr > th {
    background: ${(props: PropsTheme) => props.theme.colors.white};
    border-top: 1px solid ${(props: PropsTheme) => props.theme.colors.mediumGrey};
    border-bottom: 1px solid ${(props: PropsTheme) => props.theme.colors.mediumGrey};
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.lg};
    font-weight: bold;
    color: ${(props: PropsTheme) => props.theme.fontColor.medium};
  }

  .ant-table-thead > tr > th ::before {
    height: 0px !important;
  }

  .ant-table-tbody > tr > td {
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.lg};
    border-bottom: 1px solid ${(props: PropsTheme) => props.theme.colors.mediumGrey};
  }

  .ant-table-footer {
    background: ${(props: PropsTheme) => props.theme.colors.white};
    border-bottom: 1px solid ${(props: PropsTheme) => props.theme.colors.mediumGrey};
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.lg};
    color: ${(props: PropsTheme) => props.theme.fontColor.medium};
  }
`

export const StyledTableWithoutTopBorder = styled(StyledTable)`
  && .ant-table-thead > tr > th {
    border-top: none;
    text-align: center;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${(props: PropsTheme) => props.theme.colors.mediumGrey};
  }

  .ant-table-column-sorter {
    margin-left: 8px;
  }
`

export const StyledTableWithoutTopBorderLoading = styled(StyledTableWithoutTopBorder)`
  .ant-table-thead > tr > th {
    height: 54px !important;
  }
`

export const StyledTableWithExtraLargeText: any = styled(Table)`
  .ant-table-body {
    overflow-y: hidden !important;
  }

  .ant-table-body:hover {
    overflow-y: scroll !important;
  }

  .ant-table-thead > tr > th {
    background: ${(props: PropsTheme) => props.theme.colors.white};
    border-top: 1px solid ${(props: PropsTheme) => props.theme.colors.mediumGrey};
    border-bottom: 1px solid ${(props: PropsTheme) => props.theme.colors.mediumGrey};
    font-weight: bold;
    color: ${(props: PropsTheme) => props.theme.fontColor.dark};
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${(props: PropsTheme) => props.theme.colors.mediumGrey};
  }

  .ant-table-footer {
    background: ${(props: PropsTheme) => props.theme.colors.white};
    border-bottom: 1px solid ${(props: PropsTheme) => props.theme.colors.mediumGrey};
    font-size: 20px;
    color: ${(props: PropsTheme) => props.theme.fontColor.medium};
  }
`

export const StyledTableWithExtraLargeTextWithoutTopBorder = styled(StyledTableWithExtraLargeText)`
  && .ant-table-thead > tr > th {
    border-top: none;
  }
`

export const StyledTableWithExtraTight = styled(StyledTableWithExtraLargeText)`
  .ant-table {
    font-size: 12px;
  }

  .ant-table-tbody > tr > td {
    height: 31px;
    padding: 4px;
  }

  .ant-table-thead > tr > th {
    height: 7px;
    padding: 4px;
  }
`

export const StyledSimpleTable = styled(Table).attrs((props: { paddingTableCell?: string }) => ({
  paddingTableCell: props?.paddingTableCell
}))`
  .ant-table-thead {
    .ant-table-cell {
      background-color: ${(props: PropsTheme) => props.theme.colors?.whiteSnow};

      :first-of-type {
        padding-left: 32px;
      }

      :last-of-type {
        padding-right: 32px;
      }
    }
  }

  && .ant-table-cell {
    padding: ${(props) => props.paddingTableCell};
  }

  && .ant-table-footer {
    background-color: transparent;
  }
`

export const StyledShadowAndRoundedTable = styled(StyledTable)`
  .ant-table-cell {
    padding: 12px 16px !important;
  }

  .ant-table-thead > tr > th {
    background-color: ${(props: PropsTheme) => props.theme.colors.whiteSnow};
    :first-child {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 8px !important;
    }
  }

  .ant-table {
    box-shadow: ${(props: PropsTheme) => `0px -2px 8px ${props.theme.shadowColor.veryLight}`};
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }

  .ant-table-tbody {
    tr {
      :last-child {
        .ant-table-cell {
          :first-child {
            border-bottom-left-radius: 8px !important;
          }

          :last-child {
            border-bottom-right-radius: 8px !important;
          }
        }
      }
    }
  }
`
