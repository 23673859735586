import React, { useEffect, useState } from 'react'
import { AutoComplete, Input, InputNumber } from 'antd'
import { useDebounce } from 'hooks/useDebounce'
import styled from 'styled-components'
import { lightTheme, PropsTheme } from 'theme'

const { TextArea } = Input

export const StyledInput = styled(Input)`
  && {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    padding: 0 16px;
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
    border: ${(props: PropsTheme) => `1px solid ${props.theme.colors.mediumGrey}`};
  }

  :focus {
    border-color: ${(props: PropsTheme) => props.theme.colors.main};
  }
`

export const StyledInputPassword = styled(Input.Password)`
  && {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    padding: 0 16px;
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
    border: ${(props: PropsTheme) => `1px solid ${props.theme.colors.mediumGrey}`};
  }

  && input.ant-input {
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
  }

  :focus {
    border-color: ${(props: PropsTheme) => props.theme.colors.main};
  }
`

interface IStyledInputNumber extends PropsTheme {
  $alignItems: 'left' | 'right' | 'center'
}

export const StyledInputNumber = styled(InputNumber).attrs((props: IStyledInputNumber) => ({
  $alignItems: props?.$alignItems || 'left'
}))`
  && {
    display: grid;
    align-items: center;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
    border: ${(props: PropsTheme) => `1px solid ${props.theme.colors.mediumGrey}`};

    :focus {
      border-color: ${(props: PropsTheme) => props.theme.colors.main};
    }

    & .ant-input-number-input {
      text-align: ${(props) => props.$alignItems};
    }
  }

  input::placeholder {
    font-family: 'ibm-plex-thai';
  }
`

export const InputWithAddon = styled(Input)`
  && {
    width: 100%;
    border-radius: 8px;
  }

  .ant-input {
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
    height: 40px;
    padding: 0 16px;
    border-radius: 8px;
    border: 1px solid ${(props: PropsTheme) => props.theme.colors.mediumGrey};
    border-right: none;
  }

  && .ant-input:focus {
    border-color: ${(props: PropsTheme) => props.theme.colors.main};
  }

  .ant-input:focus ~ .ant-input-group-addon {
    border-color: ${(props: PropsTheme) => props.theme.colors.main};
    color: ${(props: PropsTheme) => props.theme.colors.main};
  }

  & .ant-input:after {
    content: '';
    border-right: 1px solid red;
  }

  & .ant-input-group-addon {
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
    color: ${(props: PropsTheme | any) => (props.color ? props.color : props.theme.fontColor.dark)};
    background: transparent;
    border-color: ${(props: PropsTheme) => props.theme.colors.mediumGrey};
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  & .ant-input-group-addon:first-child::before {
    border: none;
  }

  & .ant-input-group-addon::before {
    content: '';
    position: absolute;
    border-right: ${(props: PropsTheme) => `1px solid ${props.theme.colors.mediumGrey}`};
    top: 50%;
    height: 60%;
    left: 0;
    transform: translate(0, -50%);
  }
`

export const WrapperInputGroup = styled.div`
  display: grid;
  grid-template-columns: 30% 20% 50%;

  & input:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: none;
  }

  & input:nth-child(2) {
    text-align: center;
    border-radius: 0;
    border-left: none;
    border-right: none;
    background: white;
  }

  & .ant-input-group-wrapper input,
  & .ant-input-group-wrapper {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
`

export const InputGroupWithStyled = styled(Input.Group)`
  .ant-input:first-child,
  .ant-input:nth-child(2),
  .ant-input-affix-wrapper {
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
    height: 40px !important;
  }

  & > .ant-input:first-child {
    width: 30%;
    border-right: none !important;
    border-radius: 8px !important;
    border-top-right-radius: unset !important;
    border-bottom-right-radius: unset !important;
  }

  .ant-input:nth-child(2) {
    background: #ffffff;
    width: 20%;
    pointer-events: none;
    border-left: 0;
    border-right: 0;
  }

  .ant-input-affix-wrapper {
    width: 50%;
    border-left: none !important;
    border-radius: 8px !important;
    border-top-left-radius: unset !important;
    border-bottom-left-radius: unset !important;
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};

    .ant-input {
      width: 100% !important;
      height: unset !important;
    }
  }

  .ant-input-suffix {
    padding-left: 16px;
    border-left: ${(props: PropsTheme) => `1px solid ${props.theme.shadowColor.light}`};
  }
`

interface ITextareaStyled extends PropsTheme {
  bordered: boolean
}

// `0.5px solid ${props.theme.colors.mediumGrey}`
export const TextareaStyled = styled(TextArea).attrs((props: ITextareaStyled) => ({
  bordered: props?.bordered?.toString() ? props.bordered : true
}))`
  && {
    width: 100%;
    font-size: ${(props: ITextareaStyled) => props.theme.fontSizes.sm};
    border-radius: 8px;
    padding: 16px;
    border: ${(props: ITextareaStyled) => (!props.bordered ? 'none' : `1px solid ${props.theme.colors.mediumGrey}`)};

    :focus {
      border-color: ${(props: ITextareaStyled) => (!props.bordered ? 'unset' : props.theme.colors.main)};
    }

    box-shadow: ${(props: ITextareaStyled) => (!props.bordered ? 'unset' : '')};
  }
`
export const TextareaCommentStyled = styled(TextArea).attrs((props: ITextareaStyled) => ({
  bordered: props?.bordered?.toString() ? props.bordered : true
}))`
  && {
    width: 100%;
    font-size: ${(props: ITextareaStyled) => props.theme.fontSizes.sm};
    border-radius: 8px;
    padding: 8px;
    border: ${(props: ITextareaStyled) => (!props.bordered ? 'none' : `1px solid ${props.theme.colors.mediumGrey}`)};

    :focus {
      border-color: ${(props: ITextareaStyled) => (!props.bordered ? 'unset' : props.theme.colors.main)};
    }

    box-shadow: ${(props: ITextareaStyled) => (!props.bordered ? 'unset' : '')};
  }
`

export const InputPrefixStyled = styled(Input).attrs((props: any) => ({
  $widthGroupAddon: props.widthPrefixIcon || '40px',
  $widthInput: props.$widthInput || '80px',
  height: props.height || '40px'
}))`
  border-radius: 8px !important;
  border: ${(props: PropsTheme) => `1px solid ${props.theme.colors.mediumGrey}`};

  & .ant-input-group-addon {
    width: ${(props) => props.$widthGroupAddon};
    background: transparent;
    width: 56px;
  }

  & .ant-input-group-addon:first-child {
    border: none;
  }

  & .ant-input-group-addon::before {
    content: '';
    position: absolute;
    border-right: ${(props: PropsTheme) => `1px solid ${props.theme.colors.mediumGrey}`};
    top: 50%;
    height: 60%;
    right: 0;
    transform: translate(0, -50%);
  }

  && .ant-input-group-addon .ant-select {
    width: 100%;
    height: ${(props) => props.height};
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 0;
    border: ${(props: PropsTheme) => `1px solid ${props.theme.colors.mediumGrey}`};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  &&& .ant-select-selector {
    padding: 0;
  }

  &&& .ant-select-selection-item {
    font-size: 13px;
    padding-left: 1px;
  }

  & .ant-select-arrow {
    top: 60%;
    left: 16px;
    font-size: 8px;
  }

  &&& .ant-input {
    width: ${(props) => props.$widthInput};
    height: ${(props) => props.height};
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
    border-color: ${lightTheme.colors.mediumGrey} !important;
    border-left: none;
  }
`

export enum INPUT_TYPE {
  INPUT_STYLED = 'inputStyled',
  INPUT_NUMBER_STYLED = 'StyledInputNumber',
  INPUT_WITH_ADDON = 'inputWithAddon',
  INPUT_PREFIX_STYLED = 'inputPrefixStyled',
  TEXTAREA_STYLED = 'textareaStyled',
  TEXTAREA_COMMENT_STYLED = 'textareaCommentStyled'
}

export const TextFieldWrapper: React.FC<any> = ({
  inputType = INPUT_TYPE.INPUT_STYLED,
  isNumber = false,
  disabled = false,
  ...rest
}) => {
  const [innerValue, setInnerValue] = useState(rest?.value || null)
  const [htmlInputValue, setHtmlInputValue] = useState(null)
  const debounceInnerValue = useDebounce(htmlInputValue, 500)

  useEffect(() => {
    if (debounceInnerValue) {
      if (isNumber && innerValue) {
        rest?.setFieldValue && rest?.setFieldValue(rest?.name, parseFloat(innerValue))
        rest?.additionalChange && rest?.additionalChange(debounceInnerValue)
      } else rest?.onChange(debounceInnerValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceInnerValue])

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue
    if (isNumber) {
      newValue = event?.currentTarget?.value.replace(/.*?(\-?)(\d*\.\d+)[^\d\.]*/, '$1$2')
    } else newValue = event?.currentTarget?.value
    event?.persist()
    setHtmlInputValue(event)
    setInnerValue(newValue)
    // rest?.additionalChange && rest?.additionalChange(event)
    rest?.getValue && rest?.getValue(newValue)
  }
  useEffect(() => {
    setInnerValue(rest?.value)
  }, [rest?.value])

  if (inputType === INPUT_TYPE.INPUT_STYLED) {
    return <StyledInput {...rest} disabled={disabled} value={innerValue} onChange={handleOnChange} />
  } else if (inputType === INPUT_TYPE.INPUT_WITH_ADDON) {
    return <InputWithAddon {...rest} disabled={disabled} value={innerValue} onChange={handleOnChange} />
  } else if (inputType === INPUT_TYPE.INPUT_PREFIX_STYLED) {
    return <InputPrefixStyled {...rest} disalbed={disabled} value={innerValue} onChange={handleOnChange} />
  } else if (inputType === INPUT_TYPE.TEXTAREA_STYLED) {
    return <TextareaStyled {...rest} disalbed={disabled} value={innerValue} onChange={handleOnChange} />
  } else if (inputType === INPUT_TYPE.TEXTAREA_COMMENT_STYLED) {
    return <TextareaCommentStyled {...rest} disabled={disabled} value={innerValue} onChange={handleOnChange} />
  } else if (inputType === INPUT_TYPE.INPUT_NUMBER_STYLED) {
    return (
      <StyledInputNumber
        {...rest}
        disabled={disabled}
        value={innerValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = event?.currentTarget?.value
          setHtmlInputValue(event)
          setInnerValue(newValue)
          rest?.getValue && rest?.getValue(newValue)
        }}
        name={rest?.name}
      />
    )
  }
}

export const AutoCompleteStyled = styled(AutoComplete)`
  && .ant-select-selector {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    padding-left: 40px;
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
    border: ${(props: PropsTheme) => `1px solid ${props.theme.colors.mediumGrey}`};
  }

  :focus {
    border-color: ${(props: PropsTheme) => props.theme.colors.main};
  }

  && .ant-select-selection-search {
    left: 0;
  }

  &&&& input {
    padding-left: 16px;
    height: 40px;
  }
`

export const StyledInputCompact = styled(Input.Group)`
  && .ant-input-affix-wrapper {
    width: 100%;
    height: 40px !important;
    border-radius: 8px !important;
    padding: 0px 24px;
    border: ${(props: PropsTheme) => `1px solid ${props.theme.colors.mediumGrey}`};

    input {
      font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
      padding-left: 8px;
    }
  }

  && .ant-input-prefix {
    margin-right: 4px;
  }

  && .ant-input-clear-icon {
    margin: 0 16px;
    padding: 0;
  }
`
