import { GENERIC_PRODUCT_QR_CODE_PREFIX } from 'containers/Product/generate-generic-qr-code/constants'
import { FormikErrors, FormikTouched } from 'formik'

import { decodeBase64 } from './encoding'

export const getValidateStatusByKey = (
  errors: FormikErrors<any>,
  touched: FormikTouched<any>,
  errorKeys: string[],
  touchKeys = [],
  targetFieldHint = { field: [] as string[], open: false }
): 'error' | 'warning' | 'success' => {
  const finalTouchKeys = touchKeys.length > 0 ? touchKeys : errorKeys
  const hasError = errorKeys?.reduce((a, v) => a?.[v], errors)
  const hasTouched = finalTouchKeys?.reduce((a, v) => a?.[v], touched)

  switch (true) {
    case hasError && hasTouched:
      return 'error'
    case targetFieldHint.open && JSON.stringify(errorKeys) === JSON.stringify(targetFieldHint.field):
      return 'warning'
    default:
      return 'success'
  }
}

interface IHintTexts {
  [key: string]: IHintTexts | string
}

export const getErrorMessageByKey = (
  errors: FormikErrors<any>,
  touched: FormikTouched<any>,
  errorKeys: string[],
  touchKeys = [],
  targetFieldHint = { field: [] as string[], open: false },
  hintTexts = {} as IHintTexts
): string => {
  const finalTouchKeys = touchKeys.length > 0 ? touchKeys : errorKeys
  const hasError = errorKeys?.reduce((a, v) => a?.[v], errors) as any
  const hasTouched = finalTouchKeys?.reduce((a, v) => a?.[v], touched)
  const hintText = errorKeys?.reduce((a, v) => a?.[v], hintTexts) as string

  switch (true) {
    case hasError && !!hasTouched:
      return hasError
    case targetFieldHint.open && JSON.stringify(errorKeys) === JSON.stringify(targetFieldHint.field):
      return hintText
    default:
      return ''
  }
}

export const getHookFormValidateStatusByKey = (
  errors: any, //FormikErrors<any>, TODO: add correct type
  touched: FormikTouched<any> | boolean,
  errorKeys: string[],
  isValid: boolean,
  touchKeys = [],
  targetFieldHint = { field: [] as string[], open: false }
): 'error' | 'warning' | 'success' => {
  const finalTouchKeys = touchKeys.length > 0 ? touchKeys : errorKeys
  const hasError = errorKeys?.reduce((a, v) => a?.[v] as any, errors)?.message
  const hasTouched = finalTouchKeys?.reduce((a, v) => a?.[v], touched)

  switch (true) {
    case hasError && (hasTouched || !isValid):
      return 'error'
    case targetFieldHint.open && JSON.stringify(errorKeys) === JSON.stringify(targetFieldHint.field):
      return 'warning'
    default:
      return 'success'
  }
}
export const getHookFormErrorMessageByKey = (
  errors: any, //FormikErrors<any>, TODO: add correct type
  touched: FormikTouched<any> | boolean,
  errorKeys: string[],
  isValid: boolean,
  touchKeys = [],
  targetFieldHint = { field: [] as string[], open: false },
  hintTexts = {} as IHintTexts
): string => {
  const finalTouchKeys = touchKeys.length > 0 ? touchKeys : errorKeys
  const hasError = errorKeys?.reduce((a, v) => a?.[v] as any, errors)?.message
  const hasTouched = finalTouchKeys?.reduce((a, v) => a?.[v], touched)
  const hintText = errorKeys?.reduce((a, v) => a?.[v], hintTexts) as string

  switch (true) {
    case hasError && (!!hasTouched || !isValid):
      return hasError
    case targetFieldHint.open && JSON.stringify(errorKeys) === JSON.stringify(targetFieldHint.field):
      return hintText
    default:
      return ''
  }
}

export const hasObjectWithNullOrUndefinedValues = (array: any[], ignoreField: string[] = []) => {
  const ignoreFieldSet = new Set(ignoreField)

  console.log(
    'ARR',
    array,
    array?.some((obj) =>
      Object.entries(obj)?.some(([key, value]) => !ignoreFieldSet.has(key) && (value === null || value === undefined))
    )
  )

  return array?.some((obj) =>
    Object.entries(obj)?.some(([key, value]) => !ignoreFieldSet.has(key) && (value === null || value === undefined))
  )
}

export const checkPublicHost = (host: string) => {
  // 1. process.env.NEXT_PUBLIC_APP_URL_NO_AUTH is the public host
  // 2. map the public host and split the http(s):// to extract the host
  // 3. get the last element of the array
  // 4. check if the host is included in the public hosts

  const publicHosts = [process.env.NEXT_PUBLIC_APP_URL_NO_AUTH?.toString()].map((e) => e.split('/')?.[2] as string)
  return publicHosts.includes(host)
}

export const checkValidQRCodeFormat = (
  value: string,
  sampleFormat?: string
): { valid: boolean; uniqueId?: string; message?: string } => {
  if (value?.trim()?.length === 0) {
    return { valid: false, message: 'กรุณากรอกรหัสที่ไม่ซ้ำกัน' }
  }

  //separate each value
  const [uniqueId, _, __] = value.split('|').map((each) => each.trim())

  if (!uniqueId) {
    return {
      valid: false,
      message: `ข้อมูลควรอยู่ในรูป ${sampleFormat ? sampleFormat : 'OpPpIE2A9v|MF-20230802104315|NBB030102C06'}`
    }
  }
  //unique id length should be 10
  else if (uniqueId.trim().length !== 10) {
    return { valid: false, message: `ป้อนรหัสที่ถูกต้อง ${uniqueId}` }
  }

  return { valid: true, uniqueId }
}

export const checkIsGenericQrCode = (
  value: string
): { valid: boolean; sku?: string; conversionFactor?: number; message?: string } => {
  const skuRegexPattern = /^[a-zA-Z0-9_-]+$/

  if (!value?.trim()?.length) {
    return { valid: false, message: 'กรุณากรอกรหัสที่ไม่ซ้ำกัน' }
  }

  //separate each value
  const [prefix, sku, factor] = decodeBase64(value)
    .split('|')
    .map((each) => each.trim())

  if (prefix !== GENERIC_PRODUCT_QR_CODE_PREFIX || !sku || !skuRegexPattern?.test(sku)) {
    return { valid: false, message: 'รูปแบบรหัส QR ทั่วไปไม่ถูกต้อง' }
  }

  const conversionFactor = parseInt(factor, 10) || 1

  return { valid: true, sku, conversionFactor }
}

interface FromatResponse {
  valid: boolean
  isGeneric?: boolean
  message?: string
  uniqueId?: string
  orderId?: string
}

export const formatTracking = (val: string, sampleFormat?: string): FromatResponse => {
  const cleanStr = val
    ?.trim()
    ?.split('\n')
    ?.filter((x) => x)[0]
    ?.trim()

  if (cleanStr?.trim()?.length === 0) {
    return { valid: false, message: 'กรุณากรอกรหัสที่ไม่ซ้ำกัน' }
  }

  const strArr = cleanStr?.split('|')

  // generic uniqueId eg: TUYyMDI0MDgwNjA5MzQyNg|MF20240806093426|SFS170100C10G|LOT-A
  if (strArr?.length === 3) {
    return { valid: false, message: 'มอบหมายงานให้กับพนักงานก่อน' }
  } else if (strArr?.length === 4) {
    return { valid: true, isGeneric: true, uniqueId: cleanStr, orderId: strArr[1] }
  } else if (!cleanStr?.split('|')?.length) {
    return { valid: false, message: 'Invalid QR' }
  }
  // uniqueId eg: OpPpIE2A9v|MF-20230802104315|NBB030102C06
  else {
    const [uniqueId] = cleanStr?.split('|')

    if (!uniqueId) {
      return {
        valid: false,
        message: `ข้อมูลควรอยู่ในรูป ${sampleFormat ? sampleFormat : 'OpPpIE2A9v|MF-20230802104315|NBB030102C06'}`
      }
    } else if (uniqueId.trim().length !== 10) {
      return { valid: false, message: `ป้อนรหัสที่ถูกต้อง ${uniqueId}` }
    } else {
      return { valid: true, uniqueId }
    }
  }
}

export const formatTrackings = (val: string) => {
  return val
    ?.trim()
    ?.split('\n')
    ?.filter((x) => x)
    ?.map((y) => formatTracking(y))
}
