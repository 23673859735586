import React, { useContext } from 'react'
import { useRouter } from 'next/router'
import { Col } from 'antd'
import { Grid } from 'antd'
import { OutlinedButton } from 'components/Button'
import { OldRow } from 'components/Row'
import { TextSm } from 'components/Typography'
import { ThemeContext } from 'styled-components'
import { DarkTheme, LightTheme } from 'theme'

import { InfoCircleOutlined } from '@ant-design/icons'
const { useBreakpoint } = Grid

interface Props {
  icon?: JSX.Element
  message?: string | JSX.Element
}

export const ErrorApi: React.FC<Props> = ({ icon, message = 'เกิดข้อผิดพลาดในการแสดงข้อมูล โปรดลองใหม่อีกครั้ง' }) => {
  const screens = useBreakpoint()
  const themeContext = useContext(ThemeContext) as LightTheme | DarkTheme
  const router = useRouter()
  return (
    <div style={!screens?.xs ? { padding: '48px' } : { padding: '24px' }}>
      <OldRow gutter={[0, 24]} justify="center">
        <Col>{icon || <InfoCircleOutlined style={{ fontSize: 60, color: themeContext.colors.warning }} />}</Col>
      </OldRow>
      <OldRow gutter={[0, 96]} justify="center">
        <Col>
          <Col>{typeof message === 'string' ? <TextSm>{message}</TextSm> : message}</Col>
        </Col>
      </OldRow>
      <OldRow justify="center">
        <Col>
          <OutlinedButton onClick={() => router.reload()}>ลองใหม่</OutlinedButton>
        </Col>
      </OldRow>
    </div>
  )
}

export const ErrorNotFound: React.FC<Props> = ({ icon, message = 'ยังไม่มีรายการ' }) => {
  const themeContext = useContext(ThemeContext) as LightTheme | DarkTheme

  return (
    <div style={{ padding: '48px' }}>
      {icon && (
        <OldRow justify="center">
          <Col>{icon || <InfoCircleOutlined style={{ fontSize: 60, color: themeContext.colors.warning }} />}</Col>
        </OldRow>
      )}
      <OldRow justify="center">
        <Col>{typeof message === 'string' ? <TextSm>{message}</TextSm> : message}</Col>
      </OldRow>
    </div>
  )
}
