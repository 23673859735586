import { Radio } from 'antd'
import styled from 'styled-components'
import { PropsTheme } from 'theme'
import { lightTheme } from 'theme/light-theme'

export const RadioStyled: typeof Radio = styled(Radio)`
  && {
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
  }

  & .ant-radio-checked .ant-radio-inner {
    border-color: ${(props: PropsTheme) => props.theme.colors.main};
  }

  & .ant-radio-checked .ant-radio-inner::after {
    background-color: ${(props: PropsTheme) => props.theme.colors.main};
  }

  & .ant-radio-wrapper:hover,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${(props: PropsTheme) => props.theme.colors.main};
  }

  & .ant-radio {
    vertical-align: middle;
  }

  & .ant-radio-input {
    width: 16px;
    height: 16px;
  }
`

export const RadioVertical = styled(RadioStyled).attrs((props: { marginBottom: string }) => ({
  // marginBottom: props?.marginBottom || '8px'
}))``

export const RadioBoxStyled = styled(RadioStyled).attrs((props: { isActive?: boolean }) => ({
  isActive: props?.isActive
}))`
  border: 1px solid ${(props: any) => (props?.isActive && '#2A62FE') || '#f5f5f5'};
  padding: 8px 64px 8px 8px;
  border-radius: 5px;
  background-color: ${(props: any) => (props?.isActive && '#fafbff') || '#fbfbfb'};
`

export const dropdownStyle = {
  borderRadius: '8px',
  border: `1px solid ${lightTheme.colors.main}`,
  fontSize: lightTheme.fontSizes.sm,
  minWidth: '80px'
}
