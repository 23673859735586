import React, { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Col, Form as FormAntd, Grid, notification } from 'antd'
import { BottomBarAction } from 'components/BottomBarAction'
import { MainButton, OutlinedButton } from 'components/Button'
import { LoadingPage } from 'components/Loading'
import { OldRow } from 'components/Row'
import { Form, Formik, FormikProps, useFormikContext } from 'formik'
import {
  CREATE_PRODUCT,
  CreateProduct,
  GET_PRODUCTS,
  GET_WAREHOUSES,
  GetLeanProducts_leanProducts,
  GetProduct_product,
  GetProducts,
  GetWarehouses
} from 'graphql-shared'
import { ThemeContext } from 'styled-components'
import { DarkTheme, LightTheme } from 'theme'
import {
  ActionCRUD,
  MarketplaceSource,
  PathMenu,
  ProductStatus,
  ProductType,
  STATUS_MODAL_ALERT
} from 'utils/constants'
import { errorFocus, isEmptyObj } from 'utils/index'

import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'

import { ProductForm } from '../shared/product-form'
import { productSchema } from '../shared/yup-schema'

const { useBreakpoint } = Grid

const formItemLayout = {
  wrapperCol: { span: 24 }
}

export const cleanValues = (values: Partial<InitialValues>) => {
  if (!values?.weight) delete values.weight
  if (values?.pricingInfo) {
    values?.pricingInfo?.forEach((each) => delete each?.sourceType)
  }

  const { pricing, productAttributes, conversionFactor, ...onlyUseValues } = values

  const isThereExistsInventory = values.inventories?.some((each) => each?.location) && values.inventories?.length > 0

  return {
    ...onlyUseValues,
    pricingInfo:
      values?.pricingInfo?.length === 1
        ? values?.pricingInfo?.map(({ key, sourceRefName, ...rest }: any) => ({ ...rest, isDefault: true }))
        : values?.pricingInfo?.map(({ key, sourceRefName, ...rest }: any) => rest),
    status: values?.type === ProductType.RAW_MATERIAL.VALUE ? ProductStatus.INACTIVE : values?.status,
    inventories:
      (isThereExistsInventory &&
        values.inventories
          ?.filter((each) => each?.location)
          ?.map((item) => {
            return {
              location: item?.location,
              productPosition: item?.productPosition,
              actualAmount: item?.actualAmount || 0,
              availableAmount: item?.availableAmount || 0,
              potentialAmount: item?.potentialAmount || 0
            }
          })) ||
      values.inventories?.map((item) => {
        return {
          location: item?.location,
          productPosition: item?.productPosition,
          actualAmount: item?.actualAmount || 0,
          availableAmount: item?.availableAmount || 0,
          potentialAmount: item?.potentialAmount || 0
        }
      }),
    ratio: values.ratio.map((ratio) => ({
      product: ratio.product._id,
      amount: ratio.amount,
      type: ratio.type || 'shared',
      productSku: ratio.product.sku,
      productName: ratio.product.name
    })),
    conversionFactor: Number(conversionFactor) || undefined,
    ...(productAttributes
      ? {
          productAttributeValues: Object.values(productAttributes)
        }
      : {})
  }
}

interface IProductContext {
  productType: string
  setProductType: React.Dispatch<React.SetStateAction<string>>
  getError: (key: string) => void
  wareHousesTotal: number
}

export interface IPricingInfo {
  source?: MarketplaceSource
  minAmount: number
  pricePerUnit: number
  shippingFeePerUnit: number
  codPricePerUnit: number
  note: string
  isDefault: boolean
  sourceType?: string
  sourceRef?: string
  sourceRefName?: string
}

export interface InitialValues {
  type: string
  tags: string[]
  name: string
  productAlias: string
  categoryId?: string
  status: string
  sku: string
  category: string
  barcode: string
  pricing: any[]
  pricingInfo: IPricingInfo[]
  point: {
    packing: number
  }
  weight: number
  width: number
  height: number
  length: number
  ownFleetSize: number
  description: string
  images: {
    imageType: string
    order: number
    src: string
  }[]
  unit: string
  createdBy: string
  inventories: {
    location: string
    productPosition: string
    actualAmount: number
    potentialAmount: number
    availableAmount: number
  }[]
  ratio: { product: GetProduct_product; type: string; amount: any }[]
  onePerPackage: boolean
  productAttributes: Record<string, string | number>
  conversionFactor?: number
  conversionUnit?: string
}

const defaultValues = {}
export const ProductContext = React.createContext<Partial<IProductContext>>(defaultValues)

export const ProductCreate: React.FC<any> = () => {
  const themeContext = useContext(ThemeContext) as LightTheme | DarkTheme
  const { xs, sm, md, lg } = useBreakpoint()
  const router = useRouter()
  const { user, isLoading } = useAuth0()
  const [productType, setProductType] = useState(ProductType.NORMAL.VALUE)
  const { data: dataWarehouses, loading: loadingWarehouses } = useQuery<GetWarehouses>(GET_WAREHOUSES, {
    variables: { paginationLimit: 200, paginationOffset: 0 },
    fetchPolicy: 'cache-first'
  })
  const [submitting, setSubmitting] = useState(false)
  const [initialValues, setInitialValues] = useState<InitialValues | null>(null)
  const [notificationApi, notificationContextHolder] = notification.useNotification()

  const [createProduct] = useMutation<CreateProduct>(CREATE_PRODUCT, {
    onCompleted: (data) => {
      const product = data?.createProduct
      router.push({ pathname: PathMenu.PRODUCT_SHOW, query: { _id: product?._id } })
      notificationApi[STATUS_MODAL_ALERT.SUCCESS]({ message: `สินค้า ${product?.name} ถูกสร้างแล้ว` })
    },
    onError: (err) => {
      notificationApi[STATUS_MODAL_ALERT.ERROR]({ message: err.message })
    }
  })

  const [getProducts, { data: dataProducts, loading: loadingProducts }] = useLazyQuery<GetProducts>(GET_PRODUCTS)

  const FocusToFirstError = () => {
    const { errors, isValidating } = useFormikContext()

    useEffect(() => {
      if (!isValidating && !isEmptyObj(errors) && submitting) {
        errorFocus(errors)
        setSubmitting(false)
      }
    }, [errors, isValidating])

    return null
  }

  useEffect(() => {
    const getInitialValues = (product?: any) => {
      return {
        type: product?.type || ProductType.NORMAL.VALUE,
        tags: product?.tags || [],
        name: product?.name || '',
        status: product?.status || ProductStatus.ACTIVE,
        sku: product?.sku ? `COPY-${product?.sku}` : '',
        category: product?.category || null,
        barcode: product?.barcode || '',
        pricingInfo: product?.pricingInfo || [],
        pricing: product?.pricing || null,
        point: product?.point || {
          packing: 0
        },
        weight: product?.weight || 0,
        description: product?.description || '',
        images: product?.images || [],
        unit: 'ชิ้น',
        width: product?.width || null,
        height: product?.height || null,
        length: product?.length || null,
        inventories:
          product?.inventories?.length > 0
            ? // if there is copied product
              product?.inventories?.map((item) => {
                return {
                  location: item?.locationId || item?.location?._id,
                  productPosition: item?.productPosition,
                  actualAmount: item?.actualAmount,
                  potentialAmount: 0,
                  availableAmount: 0
                }
              })
            : dataWarehouses?.warehouses?.map((item) => {
                return {
                  location: item?._id,
                  productPosition: '',
                  actualAmount: null,
                  potentialAmount: 0,
                  availableAmount: 0
                }
              }),
        ratio: product?.ratio || [],
        onePerPackage: product?.onePerPackage || true
      } as InitialValues
    }
    if (user && !isLoading)
      if (window.localStorage.getItem('copied-product')) {
        //handling copy feature
        const copiedProduct = JSON.parse(window.localStorage.getItem('copied-product')) as GetLeanProducts_leanProducts
        if (copiedProduct?.ratio?.length === 0) setInitialValues(getInitialValues(copiedProduct as any))
        else {
          // get full products
          if (!dataProducts?.products && window.localStorage.getItem('copied-product')) {
            const idsProduct = copiedProduct?.ratio?.map((ratio) => ratio.productId)
            getProducts({ variables: { filter: { _id: { in: idsProduct } } } })
          }
          if (dataProducts?.products) {
            const product = {
              ...copiedProduct,
              ratio: copiedProduct?.ratio?.map((item) => {
                return {
                  ...item,
                  product: dataProducts?.products?.find((product) => item.productId && product._id)
                }
              })
            }

            setInitialValues(getInitialValues(product as any))
          }
        }
      } else setInitialValues(getInitialValues())
  }, [dataProducts, user, dataWarehouses, isLoading, getProducts])

  useEffect(() => {
    return () => {
      window.localStorage.getItem('copied-product') && window.localStorage.setItem('copied-product', '')
    }
  }, [])

  if (!initialValues || isLoading || loadingWarehouses || loadingProducts) return <LoadingPage />

  return (
    <ProductContext.Provider
      value={{ productType, setProductType, wareHousesTotal: dataWarehouses?.warehousesMeta?.total }}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={productSchema}
        onSubmit={(values) => {
          const payload = cleanValues(values)
          createProduct({ variables: { payload } })
        }}
      >
        {({ errors, touched, values }: FormikProps<InitialValues>) => {
          return (
            <>
              {notificationContextHolder}
              <FocusToFirstError />
              <Form>
                <FormAntd {...formItemLayout}>
                  <ProductForm errors={errors} touched={touched} values={values} actionCRUD={ActionCRUD.CREATE} />
                </FormAntd>
                <BottomBarAction>
                  <OldRow gutter={16} justify={(xs || sm || md) && !lg ? 'center' : 'end'} align="middle">
                    <Col>
                      <OutlinedButton disabled $fontSize={themeContext.fontSizes.sm}>
                        บันทึกแบบร่าง
                      </OutlinedButton>
                    </Col>
                    <Col>
                      <MainButton
                        onClick={() => setSubmitting(true)}
                        $fontSize={themeContext.fontSizes.sm}
                        $minWidth="0"
                        htmlType="submit"
                      >
                        + เพิ่มสินค้าใหม่
                      </MainButton>
                    </Col>
                  </OldRow>
                </BottomBarAction>
              </Form>
            </>
          )
        }}
      </Formik>
    </ProductContext.Provider>
  )
}
