import { Col, Modal, ModalProps, Row } from 'antd'
import { CancelButton, ConfirmButton } from 'components/Button'
import { HeaderMd } from 'components/Typography'
import styled from 'styled-components'
import { PropsTheme } from 'theme'

export const StyledHeader = styled.div`
  padding-bottom: 0.8rem;
  border-bottom: ${(props: PropsTheme) => `1px solid  ${props.theme.colors.mediumGrey}`};
`

interface Props {
  padding: string
}

export const StyledModal = styled(Modal).attrs((props: Props) => {
  return {
    padding: props.padding
  }
})`
  .ant-modal-header {
    border-bottom: 1px solid #eaeaea;
    border-radius: 8px 8px 0 0;
    margin: 0 24px;
    padding: 16px 0;
  }

  .ant-modal-close {
    margin-right: 12px;
  }

  .ant-modal-content {
    border-radius: 16px;
    box-shadow: 0px 2px 8px ${(props) => props.theme.shadowColor?.veryLight};
  }

  .ant-modal-body {
    padding: ${(props) => props?.padding || '40px'};
  }
`

export const StyledCenterModal = styled(StyledModal)`
  &&.ant-modal {
    padding-bottom: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`

interface IModalWithActionButton extends Partial<ModalProps> {
  actionButton?: boolean
  labelSubmitButton?: string
  labelCancelButton?: string
  onSubmit?: () => void
  onCancel?: () => void
  loading?: boolean
  justify?: 'end' | 'start' | 'center' | 'space-around' | 'space-between'
  padding?: string
  disabledSubmit?: boolean
  submitButtonColor?: string
}

export const ModalWithActionButton: React.FC<IModalWithActionButton> = ({
  actionButton = true,
  labelSubmitButton = 'บันทึก',
  labelCancelButton = 'ยกเลิก',
  justify = 'end' as any,
  loading = false,
  onSubmit,
  onCancel,
  children,
  title,
  disabledSubmit,
  footer = null,
  submitButtonColor = false,
  ...rest
}) => {
  return (
    <StyledModal
      {...rest}
      title={typeof title === 'string' ? <HeaderMd>{title}</HeaderMd> : title}
      footer={footer}
      onCancel={onCancel}
    >
      {children}
      {actionButton && (
        <Row gutter={16} justify={justify}>
          <Col>
            <CancelButton loading={loading} disabled={loading} onClick={onCancel}>
              {labelCancelButton}
            </CancelButton>
          </Col>
          <Col>
            <ConfirmButton
              style={submitButtonColor && { backgroundColor: submitButtonColor }}
              loading={loading}
              disabled={disabledSubmit}
              onClick={onSubmit}
            >
              {labelSubmitButton}
            </ConfirmButton>
          </Col>
        </Row>
      )}
    </StyledModal>
  )
}
