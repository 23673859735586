import * as React from 'react'
import { Col, RowProps } from 'antd'
import { OldRow } from 'components/Row'
import styled from 'styled-components'
import { PropsTheme } from 'theme'

export const BottomBarActionStyled = styled.div`
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 30;
  background: ${(props: PropsTheme) => props.theme.colors.white};
  box-shadow: ${(props: PropsTheme) => `0px -2px 4px ${props.theme.shadowColor.lightest}`};
`

export const WrapperInner = styled.div`
  padding: 16px 0;
  margin-left: calc(16% - 48px);
  margin-right: calc(16% - 48px);
`
interface Props {
  children: React.ReactNode
  justify?: RowProps['justify']
}
export const BottomBarAction: React.FC<Props> = ({ children, justify = 'center' }) => {
  return (
    <BottomBarActionStyled>
      <WrapperInner>
        <OldRow justify={justify} align="middle">
          <Col xs={24} sm={24} md={24} lg={20} xl={20} xxl={20}>
            {children}
          </Col>
        </OldRow>
      </WrapperInner>
    </BottomBarActionStyled>
  )
}

export const EndBottomBarAction: React.FC<Props> = ({ children }) => {
  return (
    <BottomBarActionStyled>
      <WrapperInner>
        <OldRow justify="center" align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            {children}
          </Col>
        </OldRow>
      </WrapperInner>
    </BottomBarActionStyled>
  )
}

// Full Screen
export const BottomBarActionFullStyled = styled.div`
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 30;
  background: ${(props: PropsTheme) => props.theme.colors.white};
  box-shadow: ${(props: PropsTheme) => `0px -2px 4px ${props.theme.shadowColor.lightest}`};
`

export const WrapperInnerFull = styled.div`
  padding: 16px 0;
  margin: 0 8%;
`
interface Props {
  children: React.ReactNode
}

export const BottomBarActionFull: React.FC<Props> = ({ children }) => {
  return (
    <BottomBarActionFullStyled>
      <WrapperInnerFull>
        <OldRow justify="end" align="middle">
          <Col>{children}</Col>
        </OldRow>
      </WrapperInnerFull>
    </BottomBarActionFullStyled>
  )
}

interface Props {
  children: React.ReactNode
}

export const BottomBarActionFullWithoutLayout: React.FC<Props> = ({ children }) => {
  return (
    <BottomBarActionFullStyled>
      <WrapperInnerFull>{children}</WrapperInnerFull>
    </BottomBarActionFullStyled>
  )
}
