import { createHash } from 'crypto'

// Base64 Encoding without padding
export const encodeBase64 = (str: string): string => {
  return Buffer.from(str, 'utf-8').toString('base64').replace(/=+$/, '')
}

// Base64 Decoding (handles strings without padding)
export const decodeBase64 = (base64Str: string): string => {
  // Calculate the number of padding characters needed
  const padding = 4 - (base64Str.length % 4)
  const paddedBase64Str = base64Str + '='.repeat(padding === 4 ? 0 : padding)
  return Buffer.from(paddedBase64Str, 'base64').toString('utf-8')
}

export const generateHash = (value: string): string => {
  return createHash('sha256').update(value).digest('hex')
}
