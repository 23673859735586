import { Col } from 'antd'
import { Grid } from 'antd'
const { useBreakpoint } = Grid
import React, { useCallback, useContext } from 'react'
import { BaseCard } from 'components/BaseCard'
import { ButtonIconDelete } from 'components/Button'
import { ImageRounded } from 'components/Image'
import { dropdownStyle, StyledSelect } from 'components/Input/select'
import { StyledInput } from 'components/Input/text'
import { OldRow } from 'components/Row'
import { StyledTable } from 'components/Table'
import { TextSm } from 'components/Typography'
import { ProductSearch } from 'containers/Product/shared/ProductSearch'
import { FieldArray, FormikErrors, FormikTouched, useFormikContext } from 'formik'
import {
  GET_LEAN_PRODUCTS_WITH_META,
  GET_PRODUCT,
  GetLeanProducts,
  GetProduct,
  GetProduct_product
} from 'graphql-shared'
import { ThemeContext } from 'styled-components'
import { DarkTheme, LightTheme } from 'theme'
import { FamilyRatioType, ProductType } from 'utils/constants'
import { formatNumber } from 'utils/format'
import { getErrorMessageByKey, getValidateStatusByKey } from 'utils/validate'

import { DeleteOutlined } from '@ant-design/icons'
import { useLazyQuery } from '@apollo/client'

import { StyledFormItem } from '../'

const columnsProductRawMaterial = [
  {
    title: '',
    dataIndex: 'images',
    key: 'images',
    width: 48,
    align: 'center',
    fixed: 'left',
    render: (text, record) => {
      return (
        <div style={{ width: 48, height: 48 }}>
          <ImageRounded src={text?.[0]?.src} width={48} height={48} />
        </div>
      )
    }
  },
  {
    title: 'ชื่อ / รหัสส่วนประกอบ',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    render: (text, record) => {
      return (
        <div>
          <TextSm>{text}</TextSm>
          <TextSm color={record.themeContext.fontColor.medium}>{record.sku}</TextSm>
        </div>
      )
    }
  },
  {
    title: 'ประเภท',
    dataIndex: '',
    key: '',
    width: '168px',
    align: 'center',
    ellipsis: true,
    render: (text, record, index) => {
      const disabledMainTypeOption = !!record.hadMainType

      return (
        <StyledSelect
          onChange={(value) => record.handleChangeType(index, value)}
          dropdownStyle={dropdownStyle}
          value={text.type}
          style={{ minWidth: 168, textAlign: 'left' }}
          options={[
            {
              value: '',
              label: 'เลือกประเภท'
            },
            {
              value: FamilyRatioType.MAIN.VALUE,
              label: FamilyRatioType.MAIN.LABEL,
              disabled: disabledMainTypeOption
            },
            {
              value: FamilyRatioType.SHARED.VALUE,
              label: FamilyRatioType.SHARED.LABEL
            }
          ]}
        />
      )
    }
  },
  {
    title: 'จำนวนที่ใช้',
    dataIndex: '',
    key: 'amount',
    width: '96px',
    align: 'center',
    ellipsis: true,
    render: (text, record, index) => {
      return (
        <StyledFormItem
          validateStatus={!text.amount && getValidateStatusByKey(record?.errors, record?.touched, ['ratio'])}
          help={!text.amount && getErrorMessageByKey(record?.errors, record?.touched, ['ratio'])}
          style={{ marginBottom: 0 }}
        >
          <StyledInput
            value={text.amount}
            type="number"
            onChange={(e) => record.handleChangeAmount(index, parseInt(e.target.value))}
            placeholder="0"
            style={{ minWidth: 96, textAlign: 'right' }}
          />
        </StyledFormItem>
      )
    }
  },
  {
    title: '',
    dataIndex: '',
    key: 'action',
    width: 16,
    align: 'center',
    render: (text, record, index) => {
      return (
        <ButtonIconDelete
          onClick={() => record.handleRemove(index)}
          type="text"
          shape="circle"
          icon={<DeleteOutlined />}
        />
      )
    }
  }
]

const columnsProductNormal = [
  {
    title: '',
    dataIndex: 'images',
    key: 'images',
    width: 48,
    fixed: 'left',
    render: (text, record) => {
      return (
        <div style={{ width: 48, height: 48 }}>
          <ImageRounded src={text?.[0]?.src} width={48} height={48} />
        </div>
      )
    }
  },
  {
    title: 'ชื่อ / รหัสสินค้า',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    render: (text, record) => {
      return (
        <div>
          <TextSm>{text}</TextSm>
          <TextSm color={record.themeContext.fontColor.medium}>{record.sku}</TextSm>
        </div>
      )
    }
  },
  {
    title: 'ราคาต่อหน่วย',
    dataIndex: ['pricing', 'originalPrice'],
    key: 'pricing',
    align: 'right',
    ellipsis: true,
    width: 120,
    render: (text, record) => {
      return <TextSm>฿{formatNumber(text)}</TextSm>
    }
  },
  {
    title: 'จำนวนที่ใช้',
    dataIndex: '',
    key: 'amount',
    width: '96px',
    align: 'center',
    render: (text, record, index) => {
      return (
        <StyledFormItem
          validateStatus={!text.amount && getValidateStatusByKey(record?.errors, record?.touched, ['ratio'])}
          help={!text.amount && getErrorMessageByKey(record?.errors, record?.touched, ['ratio'])}
        >
          <StyledInput
            value={text.amount}
            type="number"
            onChange={(e) => record.handleChangeAmount(index, parseInt(e.target.value))}
            placeholder="0"
            style={{ minWidth: 96, textAlign: 'right' }}
          />
        </StyledFormItem>
      )
    }
  },
  {
    title: '',
    dataIndex: '',
    key: 'action',
    width: 16,
    render: (text, record, index) => {
      return (
        <ButtonIconDelete
          onClick={() => record.handleRemove(index)}
          type="text"
          shape="circle"
          icon={<DeleteOutlined />}
        />
      )
    }
  }
]

interface Props {
  productType: string
  errors: FormikErrors<any>
  touched: FormikTouched<any>
  getLabelByType: (type: string, field: string) => JSX.Element
}

export const ProductRatioForm: React.FC<Props> = ({ productType, errors, touched, getLabelByType }) => {
  const themeContext = useContext(ThemeContext) as LightTheme | DarkTheme
  const { xs, sm, md, lg } = useBreakpoint()
  const { values, setFieldValue } = useFormikContext() as any
  const [getProducts, { data: dataProducts, loading: loadingProducts }] = useLazyQuery<GetLeanProducts>(
    GET_LEAN_PRODUCTS_WITH_META,
    {
      fetchPolicy: 'cache-and-network'
    }
  )
  const [getProduct, { loading: loadingProduct }] = useLazyQuery<GetProduct>(GET_PRODUCT, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (dataProduct) => {
      const ratio = {
        product: dataProduct.product,
        type: productType === ProductType.NORMAL.VALUE ? '' : FamilyRatioType.MAIN.VALUE,
        amount: null,
        productSku: dataProduct.product?.sku,
        productName: dataProduct.product?.name
      }

      const familyRatio = [...values.ratio, ratio] as {
        product: GetProduct_product
        type: string
        amount: any
        productSku: string
        productName: string
      }[]

      const familyRatioUnique = familyRatio?.filter((item, index) => {
        const pos = familyRatio?.map((item) => item?.product?._id).indexOf(item?.product?._id)
        if (pos === index) return item
      })

      setFieldValue('ratio', familyRatioUnique)
    }
  })

  const searchRawMaterial = useCallback(
    (keyword: string) => {
      if (productType === ProductType.NORMAL.VALUE)
        getProducts({
          variables: {
            filter: {
              OR: [{ name: { contains: keyword } }, { sku: { contains: keyword } }],
              type: { in: [ProductType.RAW_MATERIAL.VALUE] }
            }
          }
        })
      else if (productType === ProductType.SET.VALUE)
        getProducts({
          variables: {
            filter: {
              OR: [{ name: { contains: keyword } }, { sku: { contains: keyword } }],
              type: { in: [ProductType.NORMAL.VALUE, ProductType.RAW_MATERIAL.VALUE] }
            }
          }
        })
      else if (productType === ProductType.REF.VALUE)
        getProducts({
          variables: {
            filter: {
              OR: [{ name: { contains: keyword } }, { sku: { contains: keyword } }],
              type: { in: [ProductType.SET.VALUE, ProductType.NORMAL.VALUE, ProductType.RAW_MATERIAL.VALUE] }
            }
          }
        })
      else if (productType === ProductType.BUNDLE.VALUE)
        getProducts({
          variables: {
            filter: {
              OR: [{ name: { contains: keyword } }, { sku: { contains: keyword } }],
              type: { in: [ProductType.NORMAL.VALUE, ProductType.RAW_MATERIAL.VALUE] }
            }
          }
        })
      else if (productType === ProductType.CONVERTIBLE.VALUE)
        getProducts({
          variables: {
            filter: {
              OR: [{ name: { contains: keyword } }, { sku: { contains: keyword } }],
              type: { in: [ProductType.NORMAL.VALUE, ProductType.RAW_MATERIAL.VALUE] }
            }
          }
        })
    },
    [getProducts, productType]
  )

  return (
    <section id="product-add-product-form">
      <OldRow gutter={[0, 40]}>
        <Col span="24">
          <BaseCard
            title={`${
              productType === ProductType.NORMAL.VALUE || productType === ProductType.CONVERTIBLE.VALUE
                ? 'ส่วนประกอบสินค้า'
                : 'จัดเซตสินค้า'
            } (สูงสุด 12 รายการ)`}
            paddingAdj={(xs || sm || md) && !lg ? '20px 24px 40px 24px' : '20px 40px 40px 40px'}
          >
            <FieldArray
              name="ratio"
              render={(arrayHelpers) => {
                const handleSelect = (productStringify: string) => {
                  const product = JSON.parse(productStringify)
                  getProduct({ variables: { params: { _id: product._id } } })
                }

                const handleChangeAmount = (index: number, amount: number) => {
                  const updateRatio = values.ratio[index]
                  updateRatio.amount = amount

                  arrayHelpers.replace(index, updateRatio)
                }

                const handleChangeType = (index: number, type: string) => {
                  const updateRatio = values.ratio[index]
                  updateRatio.type = type

                  arrayHelpers.replace(index, updateRatio)
                }

                const handleRemove = (index: number) => {
                  arrayHelpers.remove(index)
                }

                const hadMainType =
                  values.ratio.filter((item) => item.type === FamilyRatioType.MAIN.VALUE).length > 0 ? true : false
                const productWithFunctions = values.ratio.map((item, index) => {
                  if (!item?.id && item.product)
                    return {
                      ...item.product,
                      amount: item.amount,
                      type: item.type,
                      key: index,
                      themeContext,
                      handleChangeAmount,
                      handleChangeType,
                      handleRemove,
                      hadMainType,
                      errors,
                      touched
                    }
                  else
                    return {
                      ...item,
                      amount: item.amount,
                      type: item.type,
                      key: index,
                      themeContext,
                      handleChangeAmount,
                      handleChangeType,
                      handleRemove,
                      hadMainType,
                      errors,
                      touched
                    }
                })

                return (
                  <OldRow gutter={[40, 0]} align="middle" justify="center">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <StyledFormItem>
                        <ProductSearch
                          funcQuery={searchRawMaterial}
                          loadingQuery={loadingProducts}
                          products={dataProducts?.leanProducts}
                          handleSelect={handleSelect}
                          placeholder={'ค้นหา และเลือกสินค้า'}
                        />
                      </StyledFormItem>
                    </Col>
                    {values.ratio.length > 0 && (
                      <Col span={24}>
                        <StyledTable
                          style={{ marginTop: '32px' }}
                          loading={loadingProduct}
                          pagination={false}
                          columns={
                            productType === ProductType.NORMAL.VALUE
                              ? (columnsProductRawMaterial as any)
                              : (columnsProductNormal as any)
                          }
                          dataSource={productWithFunctions}
                          scroll={{ x: true }}
                        />
                      </Col>
                    )}
                  </OldRow>
                )
              }}
            />
          </BaseCard>
        </Col>
      </OldRow>
    </section>
  )
}
