import { Form } from 'antd'
import styled from 'styled-components'
import { PropsTheme } from 'theme'

export const StyledFormItem = styled(Form.Item)`
  & {
    margin-bottom: 0 !important;
  }

  &.ant-form-item-has-error .ant-input-password,
  &.ant-form-item-has-error .ant-input,
  &.ant-form-item-has-error .ant-input-group-addon {
    border-color: ${(props: PropsTheme) => props.theme.colors.danger};
    color: ${(props: PropsTheme) => props.theme.colors.danger};
  }

  & input:focus,
  & .ant-select-selector:focus,
  & .ant-form-item-has-warning input,
  & .ant-picker:focus {
    border-color: ${(props: PropsTheme) => props.theme.colors.main} !important;
    box-shadow: unset !important;
  }

  & .ant-form-item-explain-success,
  & .ant-form-item-explain-warning,
  & .ant-form-item-explain-error {
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.smMinus};
    position: absolute;
  }

  &.ant-form-item-explain-warning {
    color: ${(props: PropsTheme) => props.theme.colors.main} !important;
  }

  &.ant-form-item-with-help .ant-form-item-explain {
    min-height: 0 !important;
  }
`
