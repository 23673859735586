import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { message } from 'antd'
import { notification } from 'antd'
import { Logout } from 'graphql-shared'
import { Register } from 'graphql-shared'
import { RegisterWithInviteLink } from 'graphql-shared'
import { COMPUTE_RISK, LOGIN_DATA_MOAT, LOGOUT_USER, REGISTER_USER, REGISTER_WITH_INVITE_LINK } from 'graphql-shared'
import publicIp from 'public-ip'
import querystring from 'querystring'

import { useMutation } from '@apollo/client'

import { AuthError } from './types'

/**
 * Expose functions for interacting with firebase auth
 * However, does not store user session data. Please use useSession for that.
 */

export const useAuth = () => {
  const router = useRouter()
  const [errorUser, setErrorUser] = useState<AuthError>()
  const [loading, setLoading] = useState<boolean>(false)

  const [registerUser] = useMutation<Register>(REGISTER_USER, { fetchPolicy: 'no-cache' })
  const [registerWithInviteLink] = useMutation<RegisterWithInviteLink>(REGISTER_WITH_INVITE_LINK, {
    fetchPolicy: 'no-cache'
  })
  const [logoutUser] = useMutation<Logout>(LOGOUT_USER, { fetchPolicy: 'no-cache' })

  // required by lazada for security
  const [loginDataMoat] = useMutation(LOGIN_DATA_MOAT, { fetchPolicy: 'no-cache' })
  const [computeRisk] = useMutation(COMPUTE_RISK, { fetchPolicy: 'no-cache' })
  const [notificationApi, notificationContextHolder] = notification.useNotification()

  const sendLoginResultToLazada = async (response) => {
    try {
      const userIp = await publicIp.v4()
      if (response) {
        await loginDataMoat({
          variables: {
            loginDataMoatInput: { userIp, loginResult: true, loginMessage: 'Successful Login' }
          }
        })

        const result = await computeRisk({
          variables: {
            ip: userIp
          }
        })

        if (result?.data?.computeRisk > 0.5) {
          // send email link verification
        }
      } else {
        await loginDataMoat({
          variables: {
            loginDataMoatInput: { userIp, loginResult: false, loginMessage: 'Error while logging in via Facebook' }
          }
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (errorUser) {
      message.error(errorUser.message, 5, () => {
        setErrorUser(null)
      })
    }
    setLoading(false)
  }, [errorUser])

  const signup = async (email: string, password: string, displayName: string, refCode?: string): Promise<void> => {
    //   try {
    // setLoading(true)
    // const result = await firebase.auth().createUserWithEmailAndPassword(email, password)
    // if (refCode) {
    //   await registerWithInviteLink({
    //     variables: { input: { ...formatUser(result.user), displayName, refCode } }
    //   })
    // } else {
    //   await registerUser({ variables: { input: { ...formatUser(result.user), displayName } } })
    // }
    // await result.user.updateProfile({ displayName })
    // const baseUrl = new RegExp('^https?://[^/]+').exec(window.location.href)[0]
    // await firebase.auth().currentUser.sendEmailVerification({
    //   url: baseUrl + `${PathMenu.LOGIN}?status=${ResultStatus.SUCCESS}&inform=${InformType.EMAIL_CONFIRM}`
    // })
    // await firebase.auth().signOut()
    // setLoading(false)
    // notificationApi[STATUS_MODAL_ALERT.SUCCESS]({message: 'ลงทะเบียนสำเร็จ กรุณายืนยันอีเมล'})
    // router.replace({
    //   pathname: PathMenu.LOGIN,
    //   query: {
    //     status: ResultStatus.SUCCESS,
    //     inform: InformType.REGISTER_SUCCESS
    //   }
    // })
    // } catch (error) {
    // const errorCode = error.code
    // if (errorCode === 'auth/email-already-in-user') {
    //   setErrorUser({
    //     message: 'อีเมลล์ถูกใช้แล้ว',
    //     code: error.code
    //   })
    // } else if (errorCode === 'auth/invalid-email') {
    //   setErrorUser({
    //     message: 'อีเมลล์ไม่ถูกต้อง',
    //     code: error.code
    //   })
    // } else if (errorCode === 'auth/weak-password') {
    //   setErrorUser({
    //     message: 'พาสเวิร์ดไม่แข็งแรงพอ',
    //     code: error.code
    //   })
    // } else {
    //   setErrorUser(error)
    // }
    //   }
  }

  const signin = async (email: string, password: string): Promise<void> => {
    //   try {
    //     setLoading(true)
    //     const result = await firebase.auth().signInWithEmailAndPassword(email, password)
    //     if (result.user) {
    //       setLoading(false)
    //       notificationApi[STATUS_MODAL_ALERT.SUCCESS]({ message: 'เข้าสู่ระบบสำเร็จ' })
    //       router.replace({
    //         pathname: PathMenu.LOGIN_WORKSPACE,
    //         query: {
    //           status: ResultStatus.SUCCESS,
    //           inform: InformType.LOGIN_SUCCESS,
    //           email: result.user.email
    //         }
    //       })
    //     } else {
    //       // notificationApi[STATUS_MODAL_ALERT.ERROR]({message: 'คุณยังไม่ได้ยืนยันอีเมล กรุณายืนยันอีเมล'})
    //       // router.replace({
    //       //   pathname: PathMenu.LOGIN,
    //       //   query: {
    //       //     status: ResultStatus.FAILURE,
    //       //     inform: InformType.EMAIL_UNVERIFIED,
    //       //     email: result.user.email
    //       //   }
    //       // })
    //     }
    //   } catch (error) {
    //     const errorCode = error.code
    //     if (errorCode === 'auth/user-disabled') {
    //       setErrorUser({
    //         message: 'user ไม่ถูกระงับ',
    //         code: error.code
    //       })
    //     } else if (errorCode === 'auth/invalid-email') {
    //       setErrorUser({
    //         message: 'อีเมลล์ไม่ถูกต้อง',
    //         code: error.code
    //       })
    //     } else if (errorCode === 'auth/wrong-password') {
    //       setErrorUser({
    //         message: 'พาสเวิร์ดไม่ถูกต้อง',
    //         code: error.code
    //       })
    //     } else if (errorCode === 'auth/user-not-found') {
    //       setErrorUser({
    //         message: 'ไม่พบ user ในระบบ',
    //         code: error.code
    //       })
    //     } else {
    //       setErrorUser(error)
    //     }
    //   }
  }

  const sendEmailVerification = async () => {
    //   try {
    //     setLoading(true)
    //     const baseUrl = new RegExp('^https?://[^/]+').exec(window.location.href)[0]
    //     await firebase.auth().currentUser.sendEmailVerification({
    //       url: baseUrl + `${PathMenu.LOGIN}?status=${ResultStatus.SUCCESS}&inform=${InformType.EMAIL_CONFIRM}`
    //     })
    //     notificationApi[STATUS_MODAL_ALERT.SUCCESS]({ message: 'ส่งอีเมลยืนยันแล้ว กรุณาตรวจสอบอีกครั้ง' })
    //   } catch (error) {
    //     setErrorUser(error)
    //   }
  }

  const verifyEmail = async (code?: string) => {
    //   try {
    //     const result = await firebase.auth().checkActionCode(code)
    //     if (result.operation === 'VERIFY_EMAIL') {
    //       await firebase.auth().applyActionCode(code)
    //       notificationApi[STATUS_MODAL_ALERT.SUCCESS]({ message: 'ยืนยันอีเมลสำเร็จ กรุณาเข้าสู่ระบบ' })
    //       router.replace(PathMenu.LOGIN)
    //     } else if (result.operation === 'PASSWORD_RESET') {
    //       const resetCode = code || (getFromQueryString('oobCode') as string)
    //       notificationApi[STATUS_MODAL_ALERT.SUCCESS]({
    //         message: 'ระบบกำลังนำท่านเข้าสู่หน้าเปลี่ยนรหัสผ่าน',
    //         description: 'กรุณาอย่าปิดหน้านี้'
    //       })
    //       router.replace({ pathname: PathMenu.CHANGE_PASSWORD, query: { resetCode } })
    //     }
    //   } catch (error) {
    //     console.log(error)
    //     router.replace(PathMenu.LOGIN)
    //   }
  }

  const sendPasswordResetEmail = async (email: string): Promise<void> => {
    //   try {
    //     setLoading(true)
    //     await firebase.auth().sendPasswordResetEmail(email)
    //     notificationApi[STATUS_MODAL_ALERT.SUCCESS]({ message: 'กรุณาตรวจสอบอีเมล' })
    //     router.replace(PathMenu.LOGIN)
    //   } catch (error) {
    //     const errorCode = error.code
    //     if (errorCode === 'auth/invalid-email') {
    //       setErrorUser({
    //         message: 'อีเมลล์ไม่ถูกต้อง',
    //         code: error.code
    //       })
    //     } else if (errorCode === 'auth/user-not-found') {
    //       setErrorUser({
    //         message: 'ไม่พบ user ในระบบ',
    //         code: error.code
    //       })
    //     } else {
    //       setErrorUser(error)
    //     }
    //   }
  }

  const confirmPasswordReset = async (
    newPassword: string,
    code?: string //get this from url,
  ): Promise<void> => {
    //   try {
    //     setLoading(true)
    //     const resetCode = code || (getFromQueryString('oobCode') as string)
    //     await firebase.auth().confirmPasswordReset(resetCode, newPassword)
    //     notificationApi[STATUS_MODAL_ALERT.SUCCESS]({ message: 'เปลี่ยนรหัสผ่านสำเร็จ กรุณาเข้าสู่ระบบ' })
    //     router.replace(PathMenu.LOGIN)
    //   } catch (error) {
    //     const errorCode = error.code
    //     if (errorCode === 'auth/expired-action-code') {
    //       setErrorUser({
    //         message: 'รหัสหมดอายุ คุณทำช้าไป โปรกดลืมพาสเวิร์ดใหม่',
    //         code: error.code
    //       })
    //     } else if (errorCode === 'auth/invalid-action-code') {
    //       setErrorUser({
    //         message: 'รหัสนี้ถูกใช้งานแล้ว',
    //         code: error.code
    //       })
    //     } else if (errorCode === 'auth/user-not-found') {
    //       setErrorUser({
    //         message: 'ไม่พบ user ในระบบ',
    //         code: error.code
    //       })
    //     } else if (errorCode === 'auth/argument-error') {
    //       setErrorUser({
    //         message: 'ลิงค์ไม่สมบูรณ์ โปรดกดใหม่จาก email',
    //         code: error.code
    //       })
    //     } else {
    //       console.error('error', error.code)
    //       setErrorUser(error)
    //     }
    //   }
  }

  const signinWithProvider = async (providerName) => {
    //     const { providerMethod, parameters } = firebaseProviders.find((p) => p.name === providerName)
    //     const provider = new providerMethod()
    //     if (parameters) {
    //       provider.setCustomParameters(parameters)
    //     }
    //     setLoading(true)
    //     await firebase.auth().signInWithPopup(provider)
    //     notificationApi[STATUS_MODAL_ALERT.SUCCESS]({ message: 'เข้าสู่ระบบสำเร็จ' })
    //     setLoading(false)
  }

  const signout = async () => {
    //     setLoading(true)
    //     await firebase.auth().signOut()
    //     await logoutUser()
    //     setLoading(false)
    //     notificationApi[STATUS_MODAL_ALERT.SUCCESS]({ message: 'ออกจากระบบเรียบร้อยแล้ว' })
    //     Cookie.remove('workspaceMemberIdBrowser')
    //     router.replace(PathMenu.LOGIN)
  }
  const updateEmail = async (email: string) => {
    //     setLoading(true)
    //     await firebase.auth().currentUser.updateEmail(email)
    //     setLoading(false)
  }
  const updatePassword = async (password: string, newPassword: string) => {
    //     setLoading(true)
    //     try {
    //       await reauthenticateWithCredential(password)
    //       await firebase.auth().currentUser.updatePassword(newPassword)
    //       notificationApi[STATUS_MODAL_ALERT.SUCCESS]({
    //         message: 'เปลี่ยนรหัสผ่านสำเร็จแล้ว',
    //         description: 'กรุณาออกจากระบบ และเข้าสู่ระบบใหม่อีกครั้ง'
    //       })
    //       setLoading(false)
    //     } catch (error) {
    //       const errorCode = error.code
    //       console.log(error, 'error code')
    //       if (errorCode === 'auth/wrong-password') {
    //         notificationApi[STATUS_MODAL_ALERT.ERROR]({ message: 'รหัสผ่านเดิมไม่ถูกต้อง' })
    //       }
    //       setLoading(false)
    //     }
  }

  const reauthenticateWithCredential = async (password: string) => {
    //     const user = firebase.auth().currentUser
    //     const credential = firebase.auth.EmailAuthProvider.credential(user.email, password)
    //     await firebase.auth().currentUser.reauthenticateWithCredential(credential)
  }

  const getFromQueryString = (key: string) => {
    return querystring.parse(window.location.search)[key]
  }

  return {
    error: errorUser,
    loading,
    signup,
    signin,
    signinWithProvider,
    signout,
    sendEmailVerification,
    verifyEmail,
    sendPasswordResetEmail,
    confirmPasswordReset,
    updateEmail,
    updatePassword,
    sendLoginResultToLazada
  }
}
