import React from 'react'
import { Spin } from 'antd'
import { AntParagraph } from 'components/Typography/ant-typography'

import { InfoCircleOutlined } from '@ant-design/icons'

interface NotFoundContentProps {
  loading?: boolean
}

const NotFoundContent: React.FC<NotFoundContentProps> = ({ loading }) => {
  return (
    <div className="flex flex-col justify-center items-center py-8 min-h-[100px]">
      {loading ? (
        <Spin />
      ) : (
        <>
          <InfoCircleOutlined className="text-[32px] text-black/[.3] mb-4" />
          <AntParagraph type="secondary" text="ไม่พบผลการค้นหาที่คุณต้องการ" />
        </>
      )}
    </div>
  )
}

export default NotFoundContent
