import { ProductType } from 'utils/constants'
import * as yup from 'yup'

export const productSchema = yup.object().shape({
  name: yup.string().required('กรุณาระบุชื่อสินค้า'),
  type: yup.string().required(),
  sku: yup.string().trim('ห้ามไม่ช่องว่าง หน้าหลัง').required('กรุณาระบุรหัสสินค้า'),
  status: yup.string().required('กรุณากรอกข้อมูล'),
  category: yup.string().required('กรุณาเลือกหมวดหมู่'),
  unit: yup.string().required(),
  ratio: yup.array().test('ratio-does-not-0', 'กรุณาระบุจำนวนที่มากกว่า 0', function (ratios: any) {
    return !ratios.find((ratio) => ratio.amount <= 0 || !ratio.amount)
  }),
  point: yup.object().shape({
    packing: yup
      .number()
      .typeError('กรุณาระบุคะแนนสินค้า')
      .required('กรุณาระบุคะแนนสินค้า')
      .min(0, 'กรุณาระบุจำนวนที่มากกว่า 0')
  })
})
