import React, { useState } from 'react'
import { Col, Divider, Row, Select, SelectProps } from 'antd'
import { ButtonLink } from 'components/Button'
import { dropdownStyle } from 'components/Input/select'
import { GET_ORDER_SOURCES } from 'graphql-generator'
import { MarketplaceSource } from 'utils/constants'

import { useQuery } from '@apollo/client'

import { CreateSourceRefModal } from './CreateSourceRefModal'

interface OrderSourceRefSelectProps extends SelectProps {
  orderType?: string
  allowCreate?: boolean
  disabledOptions?: string[]
}

const OrderSourceRefSelect: React.FC<OrderSourceRefSelectProps> = ({
  orderType,
  allowCreate,
  disabledOptions,
  ...props
}) => {
  const [isModalVisible, setModalVisible] = useState<boolean>(false)
  /** ------------------------------ Graphql - Queries ------------------------------ */
  const {
    data: getOrderSourcesResult,
    loading: loadingOrderSources,
    refetch: refetchOrderSources
  } = useQuery(GET_ORDER_SOURCES, {
    variables: {
      filter: {
        sourceType: { not_in: [MarketplaceSource.SHOPEE, MarketplaceSource.LAZADA, MarketplaceSource.TIKTOK] },
        ...(orderType ? { orderType: { equals: orderType } } : {})
      },
      paginationOffset: 0,
      paginationLimit: 200
    }
  })

  /** ------------------------------ Component UI Renderer ------------------------------ */

  return (
    <>
      <Select
        {...props}
        showSearch
        dropdownStyle={dropdownStyle}
        placeholder={props?.placeholder || 'เลือกช่องทางขาย'}
        loading={loadingOrderSources}
        optionFilterProp="label"
        filterOption={(input, option) => new RegExp(input, 'i').test(option.label as string)}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <ButtonLink style={{ padding: '8px 16px' }} type="link" onClick={() => refetchOrderSources()}>
              refresh
            </ButtonLink>
            {allowCreate && (
              <ButtonLink style={{ padding: '8px 16px' }} type="link" onClick={() => setModalVisible(true)}>
                + เพิ่มช่องทางขาย
              </ButtonLink>
            )}
          </div>
        )}
        data-testid="sourceRef"
      >
        {getOrderSourcesResult?.orderSources?.map((orderSource, index) => (
          <Select.Option
            key={index}
            value={orderSource._id}
            label={orderSource.name}
            disabled={disabledOptions?.includes(orderSource._id)}
            sourceType={orderSource.sourceType}
          >
            <Row justify="space-between" align="middle">
              <Col>{orderSource.name}</Col>
            </Row>
          </Select.Option>
        ))}
      </Select>

      {allowCreate && (
        <CreateSourceRefModal
          open={isModalVisible}
          setVisible={setModalVisible}
          orderSources={getOrderSourcesResult?.orderSources as any}
          onCreate={(newOrderSource) => {
            console.log(newOrderSource)
          }}
        />
      )}
    </>
  )
}

export default OrderSourceRefSelect
