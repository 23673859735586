import React, { useContext, useState } from 'react'
import { Col, Divider, Grid, Row, Spin } from 'antd'
import { BaseCard } from 'components/BaseCard'
import { ButtonLink } from 'components/Button'
import { CheckboxStyled } from 'components/Input/checkbox'
import { dropdownStyle, RadioStyled } from 'components/Input/radio'
import { StyledTreeSelect } from 'components/Input/select'
import { INPUT_TYPE, InputWithAddon, StyledInput, TextFieldWrapper } from 'components/Input/text'
import { OldRow } from 'components/Row'
import { TextSm, TextSmMinus } from 'components/Typography'
import { CategoryFormModal } from 'containers/Product/category__/components/CategoryFormModal'
import { ProductContext } from 'containers/Product/create'
import { FastField, Field, FieldProps, FormikContextType, FormikErrors, FormikTouched, useFormikContext } from 'formik'
import { category, GET_CATEGORIES, GetCategories } from 'graphql-shared'
import styled, { ThemeContext } from 'styled-components'
import { DarkTheme, LightTheme } from 'theme'
import { ActionCRUD, ProductStatus, ProductType, ProductTypeValue } from 'utils/constants'
import { sortAlphabetically } from 'utils/sort'
import { getErrorMessageByKey, getValidateStatusByKey } from 'utils/validate'

import { LoadingOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'

import { StyledFormItem } from '../'

import { ProductTagSelect } from './ProductTagSelect'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
const { useBreakpoint } = Grid

const WrapperCategory = styled.div`
  display: grid;
  position: relative;

  && button {
    position: absolute;
    right: 0;
    top: 100%;
  }
`

interface Props {
  actionCRUD: ActionCRUD
  errors: FormikErrors<any>
  touched: FormikTouched<any>
  getLabelByType: (type: string, field: string) => JSX.Element
}

export const ProductGeneralForm: React.FC<Props> = ({ actionCRUD, errors, touched, getLabelByType }) => {
  const [targetFieldHint, setTargetFieldHint] = useState({
    open: false,
    field: [] as string[]
  })
  const screens = useBreakpoint()
  const { xs, sm, md, lg } = useBreakpoint()
  const themeContext = useContext(ThemeContext) as LightTheme | DarkTheme
  const { values } = useFormikContext() as FormikContextType<any>
  const currentProductType = values?.type
  const { setProductType } = useContext(ProductContext)
  const [visibleHint, setVisibleHint] = useState(false)
  const [visibleCategoryModal, setVisibleCategoryModal] = useState(false)
  const getStatusRadio = (value) => {
    return actionCRUD === ActionCRUD.UPDATE && value !== values.type
  }

  // graphql
  const {
    data: dataCategories,
    loading: loadingCategories,
    refetch: getCategories
  } = useQuery<GetCategories>(GET_CATEGORIES)

  const isRawMaterial = () => values.type === ProductType.RAW_MATERIAL.VALUE

  const handleToggleHint = (open, field: string[]) => {
    setTargetFieldHint({ open, field })
  }

  const getTreeOptions = (categories: category[], parentPath = '/') => {
    return (
      categories?.reduce((options, eachCategory) => {
        if (eachCategory.parentPath === parentPath) {
          const children = categories.filter((each) => eachCategory.currentPath === each.parentPath)

          return [
            ...options,
            {
              key: eachCategory._id,
              value: eachCategory._id,
              label: eachCategory.name,
              disabled: eachCategory.name === 'ทั้งหมด' ? true : false,

              ...(children?.length > 0
                ? {
                    children: getTreeOptions(categories, eachCategory.currentPath)
                  }
                : {})
            }
          ].sort((a, b) => sortAlphabetically(a.title, b.title))
        }

        return options
      }, []) || []
    )
  }

  return (
    <>
      <section id="product-general-form">
        <OldRow gutter={[0, 40]}>
          <Col span="24">
            <BaseCard
              title="ข้อมูลทั่วไป"
              paddingAdj={(xs || sm || md) && !lg ? '20px 24px 40px 24px' : '20px 40px 40px 40px'}
            >
              {screens.xs ? (
                <>
                  <div style={{ marginBottom: 32 }}>
                    <OldRow align="middle">
                      <Col span={24}>
                        <TextSm>ประเภทสินค้า :</TextSm>
                      </Col>
                      <FastField name="type">
                        {({ field, form }: FieldProps) => {
                          const handleChange = (e) => {
                            const type = e.target.value
                            form.setFieldValue('type', type)
                            form.setFieldValue('ratio', [])
                            form.setFieldValue('status', ProductStatus.INACTIVE)
                            if (setProductType) {
                              setProductType(type)
                            }
                          }
                          return (
                            <>
                              <RadioStyled.Group style={{ width: '100%' }} value={field.value} onChange={handleChange}>
                                {Object.keys(ProductType)?.map((key, index) => {
                                  return (
                                    <Col span={24} key={index} style={{ marginTop: 16 }}>
                                      <RadioStyled value={ProductType[key].VALUE}>{ProductType[key].LABEL}</RadioStyled>
                                    </Col>
                                  )
                                })}
                              </RadioStyled.Group>
                            </>
                          )
                        }}
                      </FastField>
                    </OldRow>
                  </div>
                </>
              ) : (
                <OldRow gutter={[16, 32]} align="middle">
                  <Col>
                    <TextSm as="span" $padding={'0 16px 0 0'}>
                      ประเภทสินค้า : {actionCRUD === ActionCRUD.UPDATE && '(เปลี่ยนประเภท ข้อมูลลูกถูกลบ)'}
                    </TextSm>
                    <FastField name="type">
                      {({ field, form }: FieldProps) => {
                        const handleChange = (e) => {
                          const type = e.target.value
                          form.setFieldValue('type', type)
                          form.setFieldValue('ratio', [])
                          form.setFieldValue('status', ProductStatus.INACTIVE)

                          if (setProductType) {
                            setProductType(type)
                          }
                        }
                        return (
                          <>
                            <RadioStyled.Group value={field.value} onChange={handleChange}>
                              {Object.entries(ProductType).map(([key, value]) => (
                                <RadioStyled
                                  // disabled={getStatusRadio(value.VALUE)}
                                  value={value.VALUE}
                                  key={key}
                                >
                                  {value.LABEL}
                                </RadioStyled>
                              ))}
                            </RadioStyled.Group>
                          </>
                        )
                      }}
                    </FastField>
                  </Col>
                </OldRow>
              )}
              <OldRow gutter={[40, 24]} align="middle" justify="center">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <TextSmMinus>ชื่อสินค้า</TextSmMinus>
                  <FastField name="name">
                    {({ field }: FieldProps) => {
                      return (
                        <StyledFormItem
                          validateStatus={getValidateStatusByKey(errors, touched, ['name'])}
                          help={getErrorMessageByKey(errors, touched, ['name'])}
                        >
                          <InputWithAddon
                            color={themeContext.fontColor.light}
                            addonAfter={`${field.value?.length || 0}/255`}
                            {...field}
                            onChange={(e) => (e.target.value.length > 255 ? false : field.onChange(e))}
                          />
                        </StyledFormItem>
                      )
                    }}
                  </FastField>
                </Col>
              </OldRow>
              <OldRow gutter={(xs || sm || md) && !lg ? [24, 24] : [40, 24]} align="middle" justify="center">
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <TextSmMinus>ชื่อย่อสินค้า</TextSmMinus>
                  <FastField name="productAlias">
                    {({ field }: FieldProps) => {
                      return (
                        <StyledFormItem
                          validateStatus={getValidateStatusByKey(errors, touched, [''])}
                          help={getErrorMessageByKey(errors, touched, [''])}
                        >
                          <InputWithAddon
                            color={themeContext.fontColor.light}
                            addonAfter={`${field?.value?.length || 0}/32`}
                            placeholder="ใช้แสดงบนสติกเกอร์ QR Code"
                            {...field}
                            onChange={(e) => (e.target.value.length > 32 ? false : field.onChange(e))}
                          />
                        </StyledFormItem>
                      )
                    }}
                  </FastField>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <TextSmMinus>รหัสสินค้า</TextSmMinus>
                  <FastField name="sku">
                    {({ field }: FieldProps) => {
                      return (
                        <StyledFormItem
                          validateStatus={getValidateStatusByKey(errors, touched, ['sku'])}
                          help={getErrorMessageByKey(errors, touched, ['sku'])}
                        >
                          <StyledInput {...field} />
                        </StyledFormItem>
                      )
                    }}
                  </FastField>
                </Col>
              </OldRow>

              <OldRow gutter={(xs || sm || md) && !lg ? [24, 24] : [40, 24]} align="middle" justify="center">
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <TextSmMinus>หมวดหมู่</TextSmMinus>
                  <WrapperCategory>
                    {loadingCategories ? (
                      <Spin indicator={antIcon} />
                    ) : (
                      <>
                        <Field name="category">
                          {({ field, form }: FieldProps) => {
                            const handleChange = (value) => {
                              form.setFieldValue('category', value)
                            }
                            return (
                              <>
                                <CategoryFormModal
                                  action={ActionCRUD.CREATE}
                                  open={visibleCategoryModal}
                                  categories={dataCategories?.categories}
                                  loading={loadingCategories}
                                  onCancel={() => setVisibleCategoryModal(false)}
                                  afterSubmit={(category) => {
                                    getCategories()
                                    setVisibleCategoryModal(false)
                                    form.setFieldValue('category', category._id)
                                  }}
                                />
                                <StyledFormItem
                                  validateStatus={getValidateStatusByKey(errors, touched, ['category'])}
                                  help={getErrorMessageByKey(errors, touched, ['category'])}
                                >
                                  <StyledTreeSelect
                                    {...field}
                                    onChange={handleChange}
                                    dropdownStyle={dropdownStyle}
                                    placeholder="เลือกหมวดหมู่"
                                    value={field.value}
                                    showSearch
                                    treeNodeFilterProp="label"
                                    treeData={getTreeOptions(dataCategories?.categories)}
                                    dropdownRender={(menu) => (
                                      <div>
                                        {menu}
                                        <Divider style={{ margin: '8px 0' }} />
                                        <ButtonLink
                                          style={{ padding: '8px 16px' }}
                                          type="link"
                                          onClick={() => setVisibleCategoryModal(true)}
                                        >
                                          + เพิ่มหมวดหมู่
                                        </ButtonLink>
                                      </div>
                                    )}
                                  />
                                </StyledFormItem>
                              </>
                            )
                          }}
                        </Field>
                      </>
                    )}
                  </WrapperCategory>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={xs && { marginTop: 24 }}>
                  <OldRow>
                    <Col flex="auto">
                      <TextSmMinus>{getLabelByType(currentProductType, 'tags')}</TextSmMinus>
                    </Col>
                    <Col>
                      <TextSmMinus color={themeContext.fontColor.light}>(ไม่บังคับ)</TextSmMinus>
                    </Col>
                  </OldRow>
                  <FastField name="tags">
                    {({ field, form }: FieldProps) => {
                      const toggleHint = (values = field?.value) => {
                        values?.length === 0 ? setVisibleHint(true) : setVisibleHint(false)
                      }

                      return (
                        <StyledFormItem
                          validateStatus={getValidateStatusByKey(errors, touched, ['tags'])}
                          help={getErrorMessageByKey(errors, touched, ['tags'])}
                        >
                          <ProductTagSelect form={form} field={field} toggleHint={toggleHint} />
                        </StyledFormItem>
                      )
                    }}
                  </FastField>
                </Col>
              </OldRow>

              <OldRow gutter={(xs || sm || md) && !lg ? [24, 24] : [40, 24]} align="middle" justify="start">
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <TextSmMinus>หน่วยหลัก</TextSmMinus>
                  <FastField name="unit">
                    {({ field }: FieldProps) => {
                      return (
                        <StyledFormItem
                          validateStatus={getValidateStatusByKey(errors, touched, ['unit'])}
                          help={getErrorMessageByKey(errors, touched, ['unit'])}
                        >
                          <StyledInput {...field} />
                        </StyledFormItem>
                      )
                    }}
                  </FastField>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <TextSmMinus>หน่วยย่อย</TextSmMinus>
                  <FastField name="conversionUnit">
                    {({ field }: FieldProps) => {
                      return (
                        <StyledFormItem
                          validateStatus={getValidateStatusByKey(errors, touched, ['conversionUnit'])}
                          help={getErrorMessageByKey(errors, touched, ['conversionUnit'])}
                        >
                          <StyledInput {...field} />
                        </StyledFormItem>
                      )
                    }}
                  </FastField>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <TextSmMinus>หน่วยแปลง</TextSmMinus>
                  <FastField name="conversionFactor">
                    {({ field }: FieldProps) => {
                      return (
                        <StyledFormItem
                          validateStatus={getValidateStatusByKey(errors, touched, ['conversionFactor'])}
                          help={getErrorMessageByKey(errors, touched, ['conversionFactor'])}
                        >
                          <StyledInput type="number" {...field} />
                        </StyledFormItem>
                      )
                    }}
                  </FastField>
                </Col>
              </OldRow>

              {![ProductTypeValue.RAW_MATERIAL, ProductTypeValue.WIP].includes(currentProductType) && (
                <OldRow gutter={(xs || sm || md) && !lg ? [24, 0] : [40, 0]} align="middle" style={{ marginTop: 24 }}>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <TextSmMinus>{getLabelByType(currentProductType, 'cost')}</TextSmMinus>
                    <FastField name="">
                      {({ field, form }: FieldProps) => {
                        return (
                          <StyledFormItem
                            validateStatus={getValidateStatusByKey(errors, touched, [''])}
                            help={getErrorMessageByKey(errors, touched, [''])}
                          >
                            <TextFieldWrapper
                              isNumber
                              inputType={INPUT_TYPE.INPUT_WITH_ADDON}
                              placeholder="0.00"
                              addonAfter="บาท"
                              // value={field.value}
                            />
                          </StyledFormItem>
                        )
                      }}
                    </FastField>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <TextSmMinus>{getLabelByType(currentProductType, 'point')}</TextSmMinus>
                    <FastField name="point.packing">
                      {({ field, form }: FieldProps) => {
                        const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
                          const packing = e.currentTarget.value === '' ? null : parseInt(e.currentTarget.value)
                          form.setFieldValue('point.packing', packing)
                        }
                        return (
                          <StyledFormItem
                            validateStatus={getValidateStatusByKey(errors, touched, ['point', 'packing'])}
                            help={getErrorMessageByKey(errors, touched, ['point', 'packing'])}
                          >
                            <InputWithAddon
                              type="number"
                              placeholder="0"
                              addonAfter="คะแนน"
                              onChange={handleChange}
                              value={field.value}
                            />
                          </StyledFormItem>
                        )
                      }}
                    </FastField>
                  </Col>
                </OldRow>
              )}
              {!screens.xs && (
                <OldRow gutter={[16, 0]} align="middle" justify="center" style={{ marginTop: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <FastField name="status">
                      {({ field, form }: FieldProps) => {
                        const handleCheck = (e) => {
                          const checked = e.target.checked
                          if (checked) form.setFieldValue('status', ProductStatus.ACTIVE)
                          else form.setFieldValue('status', ProductStatus.INACTIVE)
                        }

                        return (
                          <CheckboxStyled
                            checked={field.value === ProductStatus.ACTIVE ? true : false}
                            onChange={handleCheck}
                          >
                            {getLabelByType(currentProductType, 'status')}
                          </CheckboxStyled>
                        )
                      }}
                    </FastField>

                    <FastField name="onePerPackage">
                      {({ field, form }: FieldProps) => {
                        const handleCheck = (e) => {
                          form.setFieldValue('onePerPackage', e.target.checked)
                        }

                        return (
                          <CheckboxStyled checked={field.value} onChange={handleCheck}>
                            สินค้าขนาดใหญ่
                          </CheckboxStyled>
                        )
                      }}
                    </FastField>
                  </Col>
                </OldRow>
              )}
              {screens.xs && (
                <>
                  <Row style={{ marginBottom: 16, marginTop: 32 }}>
                    <Col style={{ textAlign: 'center' }}>
                      <FastField name="status">
                        {({ field, form }: FieldProps) => {
                          const handleCheck = (e) => {
                            const checked = e.target.checked
                            if (checked) form.setFieldValue('status', ProductStatus.ACTIVE)
                            else form.setFieldValue('status', ProductStatus.INACTIVE)
                          }

                          return (
                            <CheckboxStyled
                              checked={field.value === ProductStatus.ACTIVE ? true : false}
                              onChange={handleCheck}
                            >
                              {getLabelByType(currentProductType, 'status')}
                            </CheckboxStyled>
                          )
                        }}
                      </FastField>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ textAlign: 'center' }}>
                      <FastField name="onePerPackage">
                        {({ field, form }: FieldProps) => {
                          const handleCheck = (e) => {
                            form.setFieldValue('onePerPackage', e.target.checked)
                          }
                          return (
                            <CheckboxStyled checked={field.value} onChange={handleCheck}>
                              สินค้าขนาดใหญ่
                            </CheckboxStyled>
                          )
                        }}
                      </FastField>
                    </Col>
                  </Row>
                </>
              )}
              <OldRow gutter={(xs || sm || md) && !lg ? [24, 0] : [40, 0]} align="middle" style={{ marginTop: 24 }}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <TextSmMinus>ขนาดสำหรับขึ้นรถ FM Own Fleet</TextSmMinus>
                  <FastField name="ownFleetSize">
                    {({ field, form }: FieldProps) => {
                      const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
                        const size = e.currentTarget.value === '' ? null : parseInt(e.currentTarget.value)
                        form.setFieldValue('ownFleetSize', size)
                      }
                      return (
                        <StyledFormItem
                          validateStatus={getValidateStatusByKey(errors, touched, ['ownFleetSize'])}
                          help={getErrorMessageByKey(errors, touched, ['ownFleetSize'])}
                        >
                          <InputWithAddon
                            type="number"
                            placeholder="0"
                            addonAfter="คะแนน"
                            onChange={handleChange}
                            value={field.value}
                          />
                        </StyledFormItem>
                      )
                    }}
                  </FastField>
                </Col>
              </OldRow>
            </BaseCard>
          </Col>
        </OldRow>
      </section>
    </>
  )
}
