import { Divider } from 'antd'
import styled from 'styled-components'
import { PropsTheme } from 'theme'

export const StyledDivider = styled(Divider)`
  .ant-divider {
    border-top: 1px solid ${(props: PropsTheme) => props.theme.colors.mediumGrey};
  }
`

export const AdvanceSearchDivider = styled(Divider)`
  margin: 32px 0 !important;
`

export const ProductShowDivider = styled(Divider)`
  margin: 8px 0 !important;
`
export const ProductSelectDivider = styled(Divider)`
  margin: 0.8rem 0 !important;
`

export const DividerFilter = styled(Divider)`
  &&.ant-divider-vertical {
    border-left: 1px solid #dddddd;
  }
`

export const PaymentConfirmDivider = styled(Divider)`
  margin: 1rem 0 !important;

  .ant-divider {
    border-color: ${(props: PropsTheme) => props.theme.colors.medium};
  }
`
