import { Divider, Row } from 'antd'
import { ButtonLink } from 'components/Button'
import { SmallLoadingIcon } from 'components/Icon'
import { StyledSelect } from 'components/Input/select'
import { OldRow } from 'components/Row'
import { CreateTag, CREATE_TAG, GetTags, GET_TAGS } from 'graphql-shared'
import React, { useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'

interface Props {
  form?: any
  field?: any
  toggleHint?: (values?: string[]) => void
}

export const ProductTagSelect: React.FC<Props> = ({ form, field, toggleHint }) => {
  const [searchValue, setSearchValue] = useState('')
  const [currentSelectedValues, setValues] = useState<string[]>(field.value)
  // graphql
  const [getTags, { data: getTagsResponse, loading: loadingTags }] = useLazyQuery<GetTags>(GET_TAGS, {
    fetchPolicy: 'cache-and-network',
    variables: { filter: { usedIn: { contains: 'product' } } }
  })
  const [createTag, { loading: loadingNewTag }] = useMutation<CreateTag>(CREATE_TAG, {
    onCompleted: (data) => {
      if (data) {
        const values = [...currentSelectedValues, data.createTag._id]
        setValues(values)
        form.setFieldValue('tags', values)
        form.setFieldTouched('tags')
        toggleHint(values)
      }
      getTags()
      setSearchValue('')
    }
  })

  const handleCreateTag = async () => {
    if (!loadingNewTag) {
      createTag({ variables: { input: { name: searchValue, usedIn: ['product'] } } })
    }
  }

  useEffect(() => {
    getTags()
  }, [getTags])

  return (
    <StyledSelect
      mode="multiple"
      loading={loadingTags || loadingNewTag}
      onBlur={() => form.setFieldTouched('tags')}
      onSearch={(value) => setSearchValue(value)}
      searchValue={searchValue}
      onChange={(values: string[]) => {
        setValues(values)
        form.setFieldValue('tags', values)
        form.setFieldTouched('tags')
        toggleHint(values)
      }}
      onFocus={() => toggleHint()}
      style={{ width: '100%' }}
      value={field.value}
      filterOption={true}
      optionFilterProp="label"
      options={getTagsResponse?.tags?.map((tag, index: number) => {
        return {
          key: index,
          value: tag._id,
          label: tag.name
        }
      })}
      dropdownRender={(menu) => (
        <div>
          {menu.props?.options?.length > 0 && (
            <>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
            </>
          )}
          {loadingTags || loadingNewTag ? (
            <OldRow style={{ paddingLeft: 8 }}>
              <SmallLoadingIcon />
            </OldRow>
          ) : (
            <ButtonLink
              style={{ padding: '4px 16px' }}
              type="link"
              onClick={async () => {
                await handleCreateTag()
              }}
              disabled={searchValue === '' || getTagsResponse?.tags?.some((each) => each.name === searchValue)}
            >
              {searchValue === ''
                ? `พิมพ์เพื่อเพิ่ม Tag สินค้า`
                : getTagsResponse?.tags?.some((each) => each.name === searchValue)
                ? `Tag สินค้าห้ามซ้ำกัน`
                : `+ กดเพื่อเพิ่ม Tag สินค้า '${searchValue}'`}
            </ButtonLink>
          )}
        </div>
      )}
    />
  )
}
