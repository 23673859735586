import { MarketplaceSource } from 'utils/constants'

import { PricingInfoRefType, UpdateProductPricingInfoFormValues } from '../types'

export const initialProductPricingInfoFormValues: UpdateProductPricingInfoFormValues = {
  source: MarketplaceSource.MANUAL,
  sourceType: MarketplaceSource.MANUAL,
  minAmount: null,
  pricePerUnit: null,
  shippingFeePerUnit: null,
  codPricePerUnit: null,
  note: null,
  productSku: null,
  refType: PricingInfoRefType.SourceRef,
  sourceRef: null
}
