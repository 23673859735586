import React from 'react'
import { Col, Row, Select } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { BaseCard } from 'components/BaseCard'
import NotFoundContent from 'components/Select/components/NotFoundContent'
import { TextSmMinus } from 'components/Typography'
import { FormikContextType, useFormikContext } from 'formik'
import { GET_MANY_PRODUCT_ATTRIBUTES, SortDirection } from 'graphql-generator'
import { ActionCRUD } from 'utils/constants'

import { CaretDownOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'

interface ProductAttributeFormProps {
  actionCRUD: ActionCRUD
}

const ProductAttributeForm: React.FC<ProductAttributeFormProps> = ({ actionCRUD }) => {
  /** ------------------------------ Hooks ------------------------------ */
  const { xs, sm, md, lg } = useBreakpoint()
  const { values, setFieldValue } = useFormikContext() as FormikContextType<any>

  /** ------------------------------ Graphql - Query and Mutation ------------------------------ */
  const { data: getManyProductAttributeResult, loading: loadingProductAttributes } = useQuery(
    GET_MANY_PRODUCT_ATTRIBUTES,
    {
      variables: {
        filter: {
          categoryId: { equals: values.category }
        },
        paginationLimit: 500,
        sort: { field: 'createdAt', direction: SortDirection.asc }
      },
      skip: !values.category
    }
  )

  /** ------------------------------ Logic ------------------------------ */
  const handleOnSelectProductAttribute = (attrId: string, attrValueId: string) => {
    let newAttributeValue = values.productAttributes || {}

    newAttributeValue[attrId] = attrValueId

    setFieldValue('productAttributes', newAttributeValue)
  }

  return (
    <section className="mb-10">
      <BaseCard
        title="คุณสมบัติสินค้า"
        paddingAdj={(xs || sm || md) && !lg ? '20px 24px 40px 24px' : '20px 40px 40px 40px'}
      >
        <Row gutter={[16, 16]} align="middle" style={{ marginBottom: 24 }}>
          {/* ------------------------------ Product Attributes ------------------------------ */}
          {getManyProductAttributeResult?.getManyProductAttributes.map((productAttribute) => (
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} key={productAttribute._id}>
              <TextSmMinus>{productAttribute.name}</TextSmMinus>
              <Select
                value={values.productAttributes?.[productAttribute._id]}
                placeholder="เลือกมูลค่าคุณสมบัติของผลิตภัณฑ์"
                size="large"
                loading={loadingProductAttributes}
                suffixIcon={<CaretDownOutlined />}
                filterOption={false}
                notFoundContent={<NotFoundContent />}
                className="w-full"
                onSelect={(val) => handleOnSelectProductAttribute(productAttribute._id, val)}
              >
                {productAttribute.values.map(({ _id, value }) => {
                  return (
                    <Select.Option key={_id} value={_id} label={value}>
                      <Row justify="space-between" className="py-[2px]">
                        <Col>
                          <TextSmMinus>{value}</TextSmMinus>
                        </Col>
                      </Row>
                    </Select.Option>
                  )
                })}
              </Select>
            </Col>
          ))}
        </Row>
      </BaseCard>
    </section>
  )
}

export default ProductAttributeForm
