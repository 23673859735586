import { Select, TreeSelect } from 'antd'
import styled from 'styled-components'
import { PropsTheme } from 'theme'
import { lightTheme } from 'theme/light-theme'

export const StyledSelect = styled(Select)`
  && {
    width: 100%;
    border-radius: 8px;
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
  }

  &&& .ant-select-selector {
    border: ${(props: PropsTheme) => `1px solid ${props.theme.colors.mediumGrey}`};
    border-radius: 8px;
    height: 40px;
    align-items: center;
    padding: 0 16px;
  }

  &&& .ant-select-selection-placeholder {
    left: 16px;
    right: 16px;
  }

  &&& .ant-select-selection-search {
    margin-inline-start: 0px;
    padding: 4px;
    padding-top: 0px;
    padding-bottom: 0px;
    top: 8%;
  }

  &&& .ant-select-selection-item {
    height: 32px;
    align-items: center;
  }

  &&& .ant-select-selection-overflow {
    align-items: center;
  }

  &&& .ant-select-selection-search-input {
    align-items: center;
  }

  &&& .ant-select-arrow {
    padding: 0 4px;
  }

  & .ant-form-item-explain,
  .ant-form-item-extra {
    position: absolute;
    top: 100%;
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.smMinus};
  }

  & .ant-select-clear {
    right: 16px;
  }
`

export const StyledTreeSelect: any = styled(TreeSelect)`
  && {
    width: 100%;
    border-radius: 8px;
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
  }

  &&& .ant-select-selector {
    border: ${(props: PropsTheme) => `1px solid ${props.theme.colors.mediumGrey}`};
    border-radius: 8px;
    height: 40px;
    align-items: center;
    padding: 0 16px;
  }

  & .ant-form-item-explain,
  .ant-form-item-extra {
    position: absolute;
    top: 100%;
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.smMinus};
  }
`

export const dropdownStyle = {
  borderRadius: '8px',
  border: `1px solid ${lightTheme.colors.main}`,
  fontSize: lightTheme.fontSizes.sm
}

export const StyledSelectMultipleMode = styled(StyledSelect)`
  &&& .ant-select-selector {
    border: ${(props: PropsTheme) => `1px solid ${props.theme.colors.mediumGrey}`};
    border-radius: 8px;
    height: auto;
    min-height: 40px;
    align-items: center;
    padding: 0 16px;
  }

  &&& .ant-select-selection-search {
    margin-inline-start: 0px;
    padding: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    top: 8%;
  }
`
