import { useContext } from 'react'
import * as React from 'react'
import { Card, CardProps } from 'antd'
import { OldRow } from 'components/Row'
import { HeaderMd } from 'components/Typography'
import { isString } from 'lodash'
import styled, { ThemeContext } from 'styled-components'
import { DarkTheme, LightTheme, PropsTheme } from 'theme'

interface Props extends CardProps {
  title?: string | React.ReactNode
  children: string | JSX.Element | JSX.Element[]
  paddingAdj?: string
  bordered?: boolean
  extra?: string | React.ReactNode
}

const WrapperPadding = styled.div`
  & .ant-card-body {
    padding: ${(props: { paddingAdj: string }) => props.paddingAdj || '1.25rem 3.5rem 2.5rem 3.5rem'};
  }
`
const WrapperPaddingSpaceBelow = styled.div`
  & .ant-card-body {
    padding: ${(props: { paddingAdj: string }) => props.paddingAdj || '1.25rem 3.5rem 2.5rem 3.5rem'};
    margin-bottom: 25px;
  }
`

const CardStyled = styled(Card)`
  box-shadow: ${(props: PropsTheme) => `0px 2px 8px ${props.theme.shadowColor.veryLight}`};
  border-radius: 16px !important;
`

export const CardTitle = styled.div`
  border-bottom: ${(props: PropsTheme) => `1px solid ${props.theme.colors.mediumGrey}`};
  padding-bottom: 1rem;
  margin-bottom: 2.5rem;
  font-size: ${(props: PropsTheme) => props.theme.fontSizes.md};
  font-weight: bold;
`

export const BaseCard: React.FC<Props> = ({ title, children, paddingAdj, bordered = false, extra, style }) => {
  const themeContext = useContext(ThemeContext) as LightTheme | DarkTheme
  return (
    <WrapperPadding paddingAdj={paddingAdj}>
      <CardStyled style={style} bordered={bordered}>
        {isString(title) ? (
          <CardTitle>
            <OldRow style={{ display: 'flex', justifyContent: 'space-between' }}>
              <HeaderMd color={themeContext.fontColor.dark}>{title}</HeaderMd>
              {extra && extra}
            </OldRow>
          </CardTitle>
        ) : (
          title
        )}
        {children}
      </CardStyled>
    </WrapperPadding>
  )
}

export const BaseCardSpaceBelow: React.FC<Props> = ({ title, children, paddingAdj }) => {
  const themeContext = useContext(ThemeContext) as LightTheme | DarkTheme
  return (
    <WrapperPaddingSpaceBelow paddingAdj={paddingAdj}>
      <CardStyled bordered={false}>
        {title && (
          <CardTitle>
            <HeaderMd color={themeContext.fontColor.dark}>{title}</HeaderMd>
          </CardTitle>
        )}
        {children}
      </CardStyled>
    </WrapperPaddingSpaceBelow>
  )
}
