import React from 'react'
import { Col, Form, Grid } from 'antd'
import { AnchorDynamic } from 'components/Anchor'
import { OldRow } from 'components/Row'
import { InitialValues } from 'containers/Product/create'
import { FormikErrors, FormikTouched } from 'formik'
import styled from 'styled-components'
import { PropsTheme } from 'theme'
import { ActionCRUD, ProductType } from 'utils/constants'

import { ProductPricingForm } from './components/product-pricing-form'
import { ProductAdditionalForm } from './components/ProductAdditionalForm'
import ProductAttributeForm from './components/ProductAttributeForm'
import { ProductGeneralForm } from './components/ProductGeneralForm'
import { ProductRatioForm } from './components/ProductRatioForm'

const { useBreakpoint } = Grid

interface Props {
  actionCRUD?: ActionCRUD
  values: InitialValues
  errors: FormikErrors<InitialValues>
  touched: FormikTouched<InitialValues>
  refetchProduct?(): void
}

export const StyledFormItem = styled(Form.Item)`
  & {
    margin-bottom: 0 !important;
  }

  &.ant-form-item-has-error .ant-input-password,
  &.ant-form-item-has-error .ant-input,
  &.ant-form-item-has-error .ant-input-group-addon {
    border-color: ${(props: PropsTheme) => props.theme.colors.danger};
    color: ${(props: PropsTheme) => props.theme.colors.danger};
  }

  & input:focus,
  & .ant-select-selector:focus,
  & .ant-form-item-has-warning input,
  & .ant-picker:focus {
    border-color: ${(props: PropsTheme) => props.theme.colors.main} !important;
    box-shadow: unset !important;
  }

  & .ant-form-item-explain-success,
  & .ant-form-item-explain-warning,
  & .ant-form-item-explain-error {
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.smMinus};
    position: absolute;
  }

  &.ant-form-item-explain-warning {
    color: ${(props: PropsTheme) => props.theme.colors.main} !important;
  }

  &.ant-form-item-with-help .ant-form-item-explain {
    min-height: 0 !important;
  }
`

export interface IFuncValidation {
  getValidateStatusByKey: (key: string) => 'success' | 'error'
  getErrorMessageByKey: (key: string) => string | FormikErrors<any> | string[] | FormikErrors<any>[]
}

export const ProductForm: React.FC<Props> = ({ values, errors, touched, actionCRUD, refetchProduct }) => {
  let anchorInfo: { href: string; title: string }[]

  if (values.type === ProductType.NORMAL.VALUE || values.type === ProductType.SET.VALUE)
    anchorInfo = [
      { href: '#product-general-form', title: 'ข้อมูลทั่วไป' },
      { href: '#product-additional-form', title: 'ข้อมูลเพิ่มเติม' },
      { href: '#product-price-form', title: 'ราคาสินค้า' },
      { href: '#product-inventory-form', title: 'คลังสินค้า' },
      { href: '#product-add-product-form', title: 'ส่วนประกอบ' }
    ]
  else
    anchorInfo = [
      { href: '#product-general-form', title: 'ข้อมูลทั่วไป' },
      { href: '#product-additional-form', title: 'ข้อมูลเพิ่มเติม' },
      { href: '#product-price-form', title: 'ราคาสินค้า' },
      { href: '#product-inventory-form', title: 'คลังสินค้า' }
    ]

  const getLabelByType = (type: string, field: string) => {
    if (type === ProductType.NORMAL.VALUE) {
      switch (field) {
        case 'pricing':
          return <>ราคาต่อหน่วย</>
        case 'tags':
          return <>Tag สินค้า</>
        case 'status':
          return <>สินค้าธรรมดาพร้อมขาย</>
        case 'point':
          return <>คะแนนสินค้า</>
        case 'cost':
          return <>ราคาต้นทุนต่อหน่วย</>
        default:
          return <></>
      }
    } else if (type === ProductType.SET.VALUE) {
      switch (field) {
        case 'pricing':
          return <>ราคาต่อหน่วย</>
        case 'tags':
          return <>Tag สินค้า</>
        case 'status':
          return <>สินค้าจัดเซตพร้อมขาย</>
        case 'point':
          return <>คะแนนสินค้า</>
        case 'cost':
          return <>ราคาต้นทุนต่อหน่วย</>
        default:
          return <></>
      }
    } else if ([ProductType.RAW_MATERIAL.VALUE, ProductType.WIP.VALUE].includes(type)) {
      switch (field) {
        case 'tags':
          return <>Tag สินค้า</>
        case 'status':
          return <>สินค้าพร้อมขาย</>
        case 'cost':
          return <>ราคาต้นทุนต่อหน่วย</>
        default:
          return <></>
      }
    } else if ([ProductType.REF.VALUE, ProductType.BUNDLE.VALUE].includes(type)) {
      switch (field) {
        case 'tags':
          return <>Tag สินค้า</>
        case 'status':
          return <>สินค้าพร้อมขาย</>
        case 'cost':
          return <>ราคาต้นทุนต่อหน่วย</>
        default:
          return <></>
      }
    } else if (type === ProductType.CONVERTIBLE.VALUE) {
      switch (field) {
        case 'pricing':
          return <>ราคาต่อหน่วย</>
        case 'tags':
          return <>Tag สินค้า</>
        case 'status':
          return <>สินค้าจัดเซตพร้อมขาย</>
        case 'point':
          return <>คะแนนสินค้า</>
        case 'cost':
          return <>ราคาต้นทุนต่อหน่วย</>
        default:
          return <></>
      }
    } else return <></>
  }

  return (
    <OldRow justify="center">
      <Col xs={24} sm={24} md={24} lg={20} xl={20} xxl={20}>
        <ProductGeneralForm getLabelByType={getLabelByType} actionCRUD={actionCRUD} errors={errors} touched={touched} />

        {values.category && <ProductAttributeForm actionCRUD={actionCRUD} />}

        <ProductAdditionalForm getLabelByType={getLabelByType} errors={errors} touched={touched} />
        <ProductPricingForm values={values?.pricingInfo} productSku={values.sku} refetchProduct={refetchProduct} />
        {/* <ProductInventoryForm actionCRUD={actionCRUD} /> */}
        {values.type !== ProductType.RAW_MATERIAL.VALUE && (
          <ProductRatioForm
            getLabelByType={getLabelByType}
            productType={values.type}
            errors={errors}
            touched={touched}
          />
        )}
      </Col>
      <Col xs={0} sm={0} md={0} lg={4} xl={4} xxl={4}>
        <AnchorDynamic anchorInfo={anchorInfo} />
      </Col>
    </OldRow>
  )
}
