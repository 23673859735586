import { Col, Row } from 'antd'
import { Grid } from 'antd'

import { DeleteOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
const { useBreakpoint } = Grid
import React, { useMemo, useState } from 'react'
import { ColumnsType } from 'antd/es/table'
import { BaseCard } from 'components/BaseCard'
import { AddButton, ButtonIconDelete } from 'components/Button'
import { OldRow } from 'components/Row'
import { StyledTable } from 'components/Table'
import { MarketplaceSourceTag } from 'components/Tag'
import { HeaderSm, TextSm } from 'components/Typography'
import { IPricingInfo } from 'containers/Product/create'
import UpdateProductPricingInfoModal from 'containers/Product/show/components/UpdateProductPricingInfoModal'
import { FieldArray, FieldArrayRenderProps } from 'formik'
import { GET_ORDER_SOURCES, GetOrderSources, GetProducts_products_pricing } from 'graphql-shared'
import styled from 'styled-components'
import { lightTheme } from 'theme'
import { ActionCRUD, MarketplaceSource, sourceLabel } from 'utils/constants'
import { formatNumber } from 'utils/format'

const PriceTable = styled(StyledTable)`
  .ant-table table {
    width: 100% !important;
  }
`

export interface PricingInfo extends GetProducts_products_pricing {
  timestamp: string
  key: string
  setVisible: any
  arrayHelpers: any
}

interface Props {
  values: IPricingInfo[]
  productSku: string
  refetchProduct?(): void
}

interface IPricingColumns {
  arrayHelpers: FieldArrayRenderProps
}

export const ProductPricingForm: React.FC<Props> = ({ values, productSku, refetchProduct }) => {
  const { xs, sm, md, lg } = useBreakpoint()
  const screens = useBreakpoint()
  const [visible, setVisible] = useState(false)
  const [action, setAction] = useState<ActionCRUD>(null)
  const [selectedPricingInfo, setSelectedPricingInfo] = useState<IPricingInfo>(null)

  //graphql
  const { data: getOrderSourcesResponse, loading: loadingOrderSources } = useQuery<GetOrderSources>(GET_ORDER_SOURCES, {
    variables: {
      filter: {
        sourceType: { in: [MarketplaceSource.SHOPEE, MarketplaceSource.LAZADA] }
      }
    },
    fetchPolicy: 'cache-first'
  })

  // maybe function shared is better
  const combinedOrderSources = useMemo(() => {
    const data =
      getOrderSourcesResponse?.orderSources?.map((each) => ({
        name: each?.name,
        sourceType: each?.sourceType
      })) || []

    return [
      ...data,
      { name: MarketplaceSource.MANUAL, sourceType: MarketplaceSource.MANUAL },
      { name: sourceLabel.PREORDER, sourceType: MarketplaceSource.DEPARTMENT_STORE }
    ]
  }, [getOrderSourcesResponse])

  const combinedValue = useMemo(
    () =>
      values?.map((each) => ({
        ...each,
        sourceType:
          combinedOrderSources?.find((eachSource) => eachSource?.name === each?.source)?.sourceType ||
          MarketplaceSource.MANUAL
      })),
    [combinedOrderSources, values]
  )

  const pricingColumns = ({ arrayHelpers }: IPricingColumns): ColumnsType<IPricingInfo> => {
    const { remove } = arrayHelpers

    return [
      {
        title: 'ช่องทางขาย',
        dataIndex: 'source',
        key: 'source',
        width: 150,
        ellipsis: true,
        render: (source: MarketplaceSource, record: any) => (
          <MarketplaceSourceTag source={source || record?.sourceRefName} sourceType={record?.sourceType} />
        )
      },
      {
        title: 'จำนวนสินค้าขั้นต่ำ',
        dataIndex: 'minAmount',
        key: 'minAmount',
        width: 136,
        align: 'center',
        ellipsis: true,
        render: (value) => {
          return <TextSm>{value}</TextSm>
        }
      },
      {
        title: 'ราคาต่อหน่วย',
        dataIndex: 'pricePerUnit',
        key: 'pricePerUnit',
        width: 128,
        align: 'right',
        ellipsis: true,
        render: (value) => {
          return <TextSm>{formatNumber(value)}</TextSm>
        }
      },
      {
        title: 'ค่าจัดส่งต่อหน่วย',
        dataIndex: 'shippingFeePerUnit',
        key: 'shippingFeePerUnit',
        width: 128,
        align: 'right',
        ellipsis: true,
        render: (value) => {
          return <TextSm>{value ? formatNumber(value) : '0.00'}</TextSm>
        }
      },
      {
        title: 'COD ต่อหน่วย',
        dataIndex: 'codPricePerUnit',
        key: 'codPricePerUnit',
        width: 128,
        align: 'right',
        ellipsis: true,
        render: (value) => {
          return <TextSm>{value ? formatNumber(value) : '0.00'}</TextSm>
        }
      },
      {
        title: 'หมายเหตุ',
        dataIndex: 'note',
        key: 'note',
        width: 160,
        align: 'center',
        ellipsis: true,
        render: (value) => {
          return <TextSm color={lightTheme?.fontColor?.medium}>{value || '-'}</TextSm>
        }
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        width: 64,
        align: 'center',
        render: (_, __, index) => {
          return <ButtonIconDelete onClick={() => remove(index)} type="link" icon={<DeleteOutlined />} />
        }
      }
    ]
  }

  return (
    <>
      <section id="product-price-form">
        <OldRow gutter={[0, 40]}>
          <Col span="24">
            <BaseCard
              title="ราคาสินค้า"
              paddingAdj={(xs || sm || md) && !lg ? '20px 24px 40px 24px' : '20px 40px 40px 40px'}
            >
              <OldRow gutter={[40, 0]} align="middle" justify="center">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <OldRow gutter={!screens?.xs ? [4, 32] : [4, 24]} align="middle">
                    <Col>
                      <TextSm>ราคาสินค้าทั้งหมด :</TextSm>
                    </Col>
                    <Col flex="auto">
                      <HeaderSm>{values?.length}</HeaderSm>
                    </Col>
                    <Col xs={screens.xs && 24}>
                      <AddButton
                        onClick={() => {
                          setAction(ActionCRUD.CREATE)
                          setVisible(true)
                        }}
                      >
                        + เพิ่มราคาสินค้า
                      </AddButton>
                    </Col>
                  </OldRow>
                  <Row style={screens?.xs && { marginTop: 32 }}>
                    <Col span={24}>
                      <FieldArray
                        name="pricingInfo"
                        render={(arrayHelpers) => {
                          return (
                            <>
                              <PriceTable
                                pagination={false}
                                columns={pricingColumns({ arrayHelpers })}
                                dataSource={combinedValue?.map((each, index) => ({ ...each, key: index }))}
                                scroll={{ x: true }}
                                notFoundMessage="ยังไม่มีรายการราคาสินค้า"
                                rowSelection={{
                                  selectedRowKeys: [combinedValue?.findIndex((each) => each?.isDefault)],
                                  type: 'radio',
                                  columnTitle: 'ค่าเริ่มต้น',
                                  columnWidth: 72,
                                  onChange: (selectedRowKeys, selectedRows) => {
                                    const oldDefaultIndex = combinedValue?.findIndex((each) => each?.isDefault)
                                    const oldDefault = combinedValue?.find((each) => each?.isDefault)

                                    arrayHelpers.replace(oldDefaultIndex, { ...oldDefault, isDefault: false })
                                    arrayHelpers.replace(
                                      selectedRowKeys[0] as number,
                                      selectedRows?.map((each) => ({ ...each, isDefault: true }))[0]
                                    )
                                  }
                                }}
                              />

                              {/* <PricingInfoModal
                                value={selectedPricingInfo}
                                getOrderSourcesResponse={getOrderSourcesResponse}
                                open={visible}
                                setVisible={setVisible}
                                arrayHelpers={arrayHelpers}
                              /> */}
                            </>
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </OldRow>
            </BaseCard>
          </Col>
        </OldRow>
      </section>

      {visible && (
        <UpdateProductPricingInfoModal
          open={visible}
          toggleModal={() => setVisible(false)}
          orderSources={combinedOrderSources}
          productSku={productSku}
          refetchProduct={refetchProduct}
          oldSorceRefs={values?.map((each) => each.sourceRef)}
        />
      )}
    </>
  )
}
