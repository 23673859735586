import { Spin } from 'antd'
import styled from 'styled-components'
import { PropsTheme } from 'theme'
import { hexToRgbA } from 'utils/index'

import { LoadingOutlined } from '@ant-design/icons'

export const LoadingIcon: React.FC = () => <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
export const SmallLoadingIcon: React.FC = () => <Spin size="small" indicator={<LoadingOutlined spin />} />
export const SmallWhiteLoadingIcon: React.FC = () => (
  <Spin size="small" indicator={<LoadingOutlined style={{ color: 'white' }} spin />} />
)

export interface IShadowWithIcon {
  themeColor: 'primary' | 'secondary' | 'success' | 'warning' | 'info' | 'danger'
  width?: string | number
  height?: string | number
  fontSize?: string | number
}

export const ShadowWithIcon = styled.div.attrs(
  ({ themeColor, width, height, fontSize }: IShadowWithIcon & PropsTheme) => ({
    themeColor,
    width: width || '32px',
    height: height || '32px',
    fontSize: fontSize || '1rem'
  })
)`
  border-radius: 360px;
  width: ${(props) => (typeof props.width === 'string' ? props.width : props.width + 'px')};
  height: ${(props) => (typeof props.height === 'string' ? props.height : props.height + 'px')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => (typeof props.fontSize === 'string' ? props.fontSize : props.fontSize + 'px')};
  box-shadow: ${(props) => {
    switch (props?.themeColor) {
      case 'primary':
        return `0px 0px 8px ${props.theme.colors.main}`
      case 'secondary':
        return `0px 0px 8px ${props.theme.colors.secondary}`
      case 'success':
        return `0px 0px 8px ${props.theme.colors.success}`
      case 'warning':
        return `0px 0px 8px ${props.theme.colors.warning}`
      case 'info':
        return `0px 0px 8px ${props.theme.colors.info}`
      case 'danger':
        return `0px 0px 8px ${props.theme.colors.danger}`
      default:
        return 'un-set'
    }
  }};
  color: ${(props) => {
    switch (props?.themeColor) {
      case 'primary':
        return props.theme.colors.main
      case 'secondary':
        return props.theme.colors.secondary
      case 'success':
        return props.theme.colors.success
      case 'warning':
        return props.theme.colors.warning
      case 'info':
        return props.theme.colors.info
      case 'danger':
        return props.theme.colors.danger
      default:
        return 'un-set'
    }
  }};

  svg {
    width: 100%;
  }
`

export interface IBgWithIcon {
  $themeColor: 'primary' | 'secondary' | 'success' | 'warning' | 'info' | 'danger' | 'dark'
  $width?: string | number
  $height?: string | number
  $fontSize?: string | number
  $opacityBackground?: number
  $opacityColor?: number
  $backgroundColor?: string
}

export const BgWithIcon = styled.div.attrs((props: IBgWithIcon & PropsTheme) => ({
  ...props,
  $width: props?.$width || 32,
  $height: props?.$height || 32,
  $fontSize: props?.$fontSize || '1rem',
  $opacityBackground: props?.$opacityBackground || 1,
  $opacityColor: props?.$opacityColor || 1
}))`
  border-radius: 360px;
  width: ${(props) => (typeof props.$width === 'string' ? props.$width : props.$width + 'px')};
  height: ${(props) => (typeof props.$height === 'string' ? props.$height : props.$height + 'px')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => (typeof props.$fontSize === 'string' ? props.$fontSize : props.$fontSize + 'px')};
  background: ${(props) => {
    if (props?.$backgroundColor) return hexToRgbA(props?.$backgroundColor, props?.$opacityBackground)
    else
      switch (props?.$themeColor) {
        case 'primary':
          return hexToRgbA(props.theme.colors.main, props.$opacityBackground)
        case 'secondary':
          return hexToRgbA(props.theme.colors.secondary, props.$opacityBackground)
        case 'success':
          return hexToRgbA(props.theme.fontColor.success, props.$opacityBackground)
        case 'warning':
          return hexToRgbA(props.theme.fontColor.warning, props.$opacityBackground)
        case 'info':
          return hexToRgbA(props.theme.colors.info, props.$opacityBackground)
        case 'danger':
          return hexToRgbA(props.theme.fontColor.danger, props.$opacityBackground)
        default:
          return 'unset'
      }
  }};
  color: ${(props) => {
    switch (props?.$themeColor) {
      case 'primary':
        return hexToRgbA(props.theme.colors.main, props.$opacityColor)
      case 'secondary':
        return hexToRgbA(props.theme.colors.secondary, props.$opacityColor)
      case 'success':
        return hexToRgbA(props.theme.fontColor.success, props.$opacityColor)
      case 'warning':
        return hexToRgbA(props.theme.fontColor.warning, props.$opacityColor)
      case 'info':
        return hexToRgbA(props.theme.colors.info, props.$opacityColor)
      case 'danger':
        return hexToRgbA(props.theme.fontColor.danger, props.$opacityColor)
      default:
        return 'unset'
    }
  }};

  svg {
    /* width: 100%; */
  }
`
