import * as React from 'react'
import { Anchor } from 'antd'
import styled from 'styled-components'
import { PropsTheme } from 'theme'

const { Link } = Anchor

interface AnchorInfo {
  href: string
  title: string
}

interface Props {
  anchorInfo: AnchorInfo[]
  style?: React.CSSProperties
}

const WrapperSticky = styled.div`
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 15%;
  right: 0%;
`

const AnchorStyled = styled(Anchor)`
  && {
    background-color: transparent;
  }

  & .ant-anchor-link {
    line-height: 1.6;
  }

  & .ant-anchor-link-title {
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
    color: ${(props: PropsTheme) => props.theme.fontColor.dark};
  }

  & .ant-anchor-link-active > .ant-anchor-link-title,
  a:hover {
    color: ${(props: PropsTheme) => props.theme.colors.main};
  }

  & .ant-anchor-ink::before {
    background-color: ${(props: PropsTheme) => props.theme.colors.mediumGrey};
  }

  & .ant-anchor-ink-ball {
    border-color: ${(props: PropsTheme) => props.theme.colors.main};
  }
`

export const AnchorDynamic: React.FC<Props> = ({ anchorInfo, style }) => {
  return (
    <WrapperSticky style={{ ...style }}>
      <AnchorStyled targetOffset={250}>
        {anchorInfo.map((item, index) => {
          return <Link key={index} href={item.href} title={item.title} />
        })}
      </AnchorStyled>
    </WrapperSticky>
  )
}
