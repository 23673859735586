import { MarketplaceSource } from 'utils/constants'
import * as yup from 'yup'

import { PricingInfoRefType } from '../types'

export const productPricingInfoUpdateSchema = yup.object().shape({
  minAmount: yup.number().nullable().required('กรุณาระบุจำนวนสินค้าขั้นต่ำ').min(0),
  pricePerUnit: yup.number().nullable().required('กรุณาระบุราคาต่อหน่วย').min(0),

  /** sourceType is hidden field to handle validation */
  shippingFeePerUnit: yup.number().when('sourceType', {
    is: MarketplaceSource.MANUAL,
    then: yup.number().nullable().required('กรุณาระบุค่าจัดส่งต่อหน่วย').min(0),
    otherwise: yup.number().nullable()
  }),
  codPricePerUnit: yup.number().when('sourceType', {
    is: MarketplaceSource.MANUAL,
    then: yup.number().nullable().required('กรุณาระบุ COD ต่อหน่วย').min(0),
    otherwise: yup.number().nullable()
  }),
  source: yup.string().when('refType', {
    is: PricingInfoRefType.Source,
    then: yup.string().nullable().required('กรุณาเลือกช่องทางขาย'),
    otherwise: yup.string().nullable()
  }),
  sourceRef: yup.string().when('refType', {
    is: PricingInfoRefType.SourceRef,
    then: yup.string().nullable().required('กรุณาเลือกช่องทางขาย'),
    otherwise: yup.string().nullable()
  })
})
