import * as yup from 'yup'

export const categorySchema = yup.object().shape({
  name: yup.string().required('กรุณาระบุชื่อหมวดหมู่'),
  parentPath: yup.string(),
  pathName: yup
    .string()
    .required('กรุณาระบุรหัสหมวดหมู่')
    .matches(/^([a-z][a-z0-9]*)(-[a-z0-9]+)*$/, 'ภาษาอังกฤษ a-z ตัวเลข 0-9 และ - เท่านั้น ห้ามมีช่องว่าง'),
  point: yup.object().shape({
    packing: yup
      .number()
      .typeError('กรุณาระบุคะแนนหมวดหมู่')
      .required('กรุณาระบุคะแนนหมวดหมู่')
      .min(0, 'กรุณาระบุจำนวนที่มากกว่า 0')
  }),
  shopeeCategoryFeeId: yup.string().optional()
})
