import { Col, Form as FormAntd, Row, Spin } from 'antd'
import { Grid } from 'antd'
const { useBreakpoint } = Grid
import React, { useContext } from 'react'
import { notification } from 'antd'
import { CancelButton, ConfirmButton } from 'components/Button'
import { dropdownStyle, StyledTreeSelect } from 'components/Input/select'
import { InputWithAddon, TextFieldWrapper } from 'components/Input/text'
import { StyledModal } from 'components/Modal'
import { OldRow } from 'components/Row'
import { HeaderMd, TextSmMinus } from 'components/Typography'
import { StyledFormItem } from 'containers/Product/shared/product-form'
import { FastField, FieldProps, Formik, FormikProps } from 'formik'
import {
  category,
  CREATE_CATEGORY,
  CreateCategory,
  shopeeCategoryFee,
  UPDATE_CATEGORY,
  UpdateCategory
} from 'graphql-shared'
import { ThemeContext } from 'styled-components'
import { DarkTheme, LightTheme } from 'theme'
import { ActionCRUD, STATUS_MODAL_ALERT } from 'utils/constants'
import { extractGQLApolloErrorMessage } from 'utils/format'
import { sortAlphabetically } from 'utils/sort'
import { getErrorMessageByKey, getValidateStatusByKey } from 'utils/validate'

import { LoadingOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'

import { categorySchema } from '../yup-schema'

interface Props {
  action?: ActionCRUD
  initialValues?: category
  categories?: category[]
  shopeeCategories?: shopeeCategoryFee[]
  open?: boolean
  onCancel?: () => void
  afterSubmit?: (category: category) => void
  loading?: boolean
}

interface CategoryClassForm {
  name: string
  pathName: string
  parentPath: string
  point: {
    packing: number
  }
  shopeeCategoryFeeId?: string
}

const emptyValues = {
  name: '',
  pathName: '',
  parentPath: '',
  point: {
    packing: 0
  },
  shopeeCategoryFeeId: ''
}

export const CategoryFormModal: React.FC<Props> = ({
  action,
  initialValues,
  categories,
  shopeeCategories,
  onCancel,
  afterSubmit,
  open,
  loading
}) => {
  const { xs, sm, md, lg } = useBreakpoint()
  const themeContext = useContext(ThemeContext) as LightTheme | DarkTheme
  const [notificationApi, notificationContextHolder] = notification.useNotification()

  const [createCategory] = useMutation<CreateCategory>(CREATE_CATEGORY, {
    onCompleted: (data) => {
      notificationApi[STATUS_MODAL_ALERT.SUCCESS]({ message: 'เพิ่มหมวดหมู่สำเร็จ' })
      afterSubmit(data?.createCategory)
    },
    onError: (errors) => {
      notificationApi[STATUS_MODAL_ALERT.ERROR]({
        message: `ไม่สามารถสร้างหมวดหมู่ได้  ${extractGQLApolloErrorMessage(errors)}`
      })
    }
  })

  const [updateCategory] = useMutation<UpdateCategory>(UPDATE_CATEGORY, {
    onCompleted: (data) => {
      notificationApi[STATUS_MODAL_ALERT.SUCCESS]({ message: 'แก้ไขหมวดหมู่สำเร็จ' })
      afterSubmit(data?.updateCategory?.[0])
    },
    onError: (errors) => {
      notificationApi[STATUS_MODAL_ALERT.ERROR]({
        message: `ไม่สามารถแก้ไขหมวดหมู่ได้  ${extractGQLApolloErrorMessage(errors)}`
      })
    }
  })

  return (
    <div>
      {notificationContextHolder}
      <StyledModal
        title={<HeaderMd>{action === ActionCRUD.CREATE ? 'เพิ่มหมวดหมู่' : 'แก้ไขหมวดหมู่'}</HeaderMd>}
        open={open}
        footer={null}
        onCancel={onCancel}
        width={560}
        padding={(xs || sm || md) && !lg ? '40px 24px' : '40px 64px'}
      >
        <Formik
          enableReinitialize
          initialValues={
            initialValues
              ? {
                  ...initialValues,
                  pathName: initialValues?.currentPath?.split('/')?.[initialValues?.currentPath?.split('/')?.length - 1]
                }
              : emptyValues
          }
          onSubmit={(values, { resetForm }) => {
            const categoryPayload = {
              name: values?.name,
              parentPath: values?.parentPath || '/',
              currentPath: `${values?.parentPath}/${values?.pathName}`,
              point: {
                packing: values?.point.packing
              }
            }

            if (action === ActionCRUD.CREATE) {
              createCategory({
                variables: {
                  payload: categoryPayload
                }
              })
              resetForm()
            } else if (action === ActionCRUD.UPDATE) {
              updateCategory({
                variables: {
                  params: {
                    id: initialValues._id
                  },
                  payload: categoryPayload
                }
              })
              resetForm()
            }
          }}
          validationSchema={categorySchema}
        >
          {({ errors, touched, handleSubmit }: FormikProps<CategoryClassForm>) => {
            return (
              <FormAntd onFinish={handleSubmit}>
                <>
                  <OldRow gutter={[40, 24]} justify="center">
                    <Col xs={24} sm={18} md={18} lg={24} xl={18} xxl={18}>
                      <TextSmMinus>ชื่อหมวดหมู่</TextSmMinus>
                      <StyledFormItem
                        validateStatus={getValidateStatusByKey(errors, touched, ['name'])}
                        help={getErrorMessageByKey(errors, touched, ['name'])}
                      >
                        <FastField name="name">
                          {({ field }: FieldProps) => {
                            return <TextFieldWrapper {...field} placeholder="เช่น เฟอร์นิเจอร์" />
                          }}
                        </FastField>
                      </StyledFormItem>
                    </Col>
                  </OldRow>
                  <OldRow gutter={[40, 24]} justify="center">
                    <Col xs={24} sm={18} md={18} lg={24} xl={18} xxl={18}>
                      <TextSmMinus>รหัสหมวดหมู่</TextSmMinus>
                      <StyledFormItem
                        validateStatus={getValidateStatusByKey(errors, touched, ['pathName'])}
                        help={getErrorMessageByKey(errors, touched, ['pathName'])}
                      >
                        <FastField name="pathName">
                          {({ field }: FieldProps) => {
                            return <TextFieldWrapper {...field} placeholder="เช่น furniture" />
                          }}
                        </FastField>
                      </StyledFormItem>
                    </Col>
                  </OldRow>
                  <OldRow gutter={[40, 24]} justify="center">
                    <Col xs={24} sm={18} md={18} lg={24} xl={18} xxl={18}>
                      <OldRow>
                        <Col flex="auto">
                          <TextSmMinus>อยู่ในหมวดหมู่</TextSmMinus>
                        </Col>
                        <Col>
                          <TextSmMinus color={themeContext.fontColor.light}>(ไม่บังคับ)</TextSmMinus>
                        </Col>
                      </OldRow>
                      <StyledFormItem
                        validateStatus={getValidateStatusByKey(errors, touched, ['parentPath'])}
                        help={getErrorMessageByKey(errors, touched, ['parentPath'])}
                      >
                        {loading ? (
                          <Spin indicator={<LoadingOutlined />} spinning={loading} />
                        ) : (
                          <FastField name="parentPath">
                            {({ field, form }: FieldProps) => {
                              return (
                                <StyledTreeSelect
                                  {...field}
                                  onChange={(value) => form.setFieldValue('parentPath', value)}
                                  dropdownStyle={dropdownStyle}
                                  showSearch
                                  treeNodeFilterProp="title"
                                  treeData={getTreeOptions(categories)}
                                />
                              )
                            }}
                          </FastField>
                        )}
                      </StyledFormItem>
                    </Col>
                  </OldRow>

                  <OldRow gutter={[40, 40]} justify="center">
                    <Col xs={24} sm={18} md={18} lg={24} xl={18} xxl={18}>
                      <TextSmMinus>คะแนนหมวดหมู่</TextSmMinus>
                      <FastField name="point.packing">
                        {({ field, form }: FieldProps) => {
                          const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
                            const packing = e.currentTarget.value === '' ? null : parseInt(e.currentTarget.value)
                            form.setFieldValue('point.packing', packing)
                          }
                          return (
                            <StyledFormItem
                              validateStatus={getValidateStatusByKey(errors, touched, ['point', 'packing'])}
                              help={getErrorMessageByKey(errors, touched, ['point', 'packing'])}
                            >
                              <InputWithAddon
                                type="number"
                                placeholder="0"
                                addonAfter="คะแนน"
                                onChange={handleChange}
                                value={field.value}
                              />
                            </StyledFormItem>
                          )
                        }}
                      </FastField>
                    </Col>
                  </OldRow>

                  <Row gutter={16} justify={(xs || sm || md) && !lg ? 'center' : 'end'}>
                    <Col>
                      <CancelButton onClick={onCancel}>ยกเลิก</CancelButton>
                    </Col>
                    <Col>
                      <ConfirmButton htmlType="submit">บันทึก</ConfirmButton>
                    </Col>
                  </Row>
                </>
              </FormAntd>
            )
          }}
        </Formik>
      </StyledModal>
    </div>
  )
}

const getShopeeCategoryOptions = (categories: shopeeCategoryFee[]) => {
  return categories.map((cat) => {
    return { title: cat.name, value: cat._id }
  })
}

const getTreeOptions = (categories: category[], parentPath = '/') => {
  return (
    categories?.reduce((options, eachCategory) => {
      if (eachCategory.parentPath === parentPath) {
        const children = categories.filter((each) => eachCategory.currentPath === each.parentPath)

        return [
          ...options,
          {
            title: eachCategory.name,
            value: eachCategory.currentPath,
            ...(children?.length > 0
              ? {
                  children: getTreeOptions(categories, eachCategory.currentPath)
                }
              : {})
          }
        ].sort((a, b) => sortAlphabetically(a.title, b.title))
      }

      return options
    }, []) || []
  )
}
