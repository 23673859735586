import Image from 'next/legacy/image'
import { Image as ImageAnt } from 'antd'
import styled from 'styled-components'
import { PropsTheme } from 'theme'

interface IImageWithRadius extends PropsTheme {
  width: number | string
  height: number | string
}

export const ImageWithDefault = ({ src = '', width, height, ...rest }) => {
  return (
    <Image
      {...rest}
      src={src || '/static/images/default-image.svg'}
      width={width}
      height={height}
      alt={src || ''}
      unoptimized={true}
    />
  )
}

export const ImageRounded = styled(ImageWithDefault)`
  width: ${(props: IImageWithRadius) => (props.width ? `${props.width}px` : '48px')};
  height: ${(props: IImageWithRadius) => (props.height ? `${props.height}px` : '48px')};
  border-radius: 360px;
  box-shadow: 0px 0px 6px ${(props: PropsTheme) => props.theme.shadowColor.lightest};
  object-fit: cover;
`

export const ImageWithRadius = styled(ImageWithDefault)`
  width: ${(props: IImageWithRadius) => (props.width ? `${props.width}px` : 'auto')};
  height: ${(props: IImageWithRadius) => (props.height ? `${props.height}px` : 'auto')};
  box-shadow: 0px 0px 6px ${(props: IImageWithRadius) => props.theme.shadowColor.lightest};
  border-radius: 8px;
  object-fit: cover;
`

export const ImagePreview = styled(ImageAnt)`
  width: ${(props: IImageWithRadius) => (props.width ? `${props.width}px` : 'auto')};
  height: ${(props: IImageWithRadius) => (props.height ? `${props.height}px` : 'auto')};
  box-shadow: 0px 0px 6px ${(props: IImageWithRadius) => props.theme.shadowColor.lightest};
  object-fit: cover;

  img {
    border-radius: 8px;
  }

  :hover {
    opacity: 0.8;
    cursor: pointer;
  }
`

interface InterfaceImageAnonymous {
  $hexColor: string
  $width?: number
  $height?: number
}

export const ImageAnonymous = styled.div.attrs((props: InterfaceImageAnonymous) => {
  return {
    $hexColor: props?.$hexColor,
    $width: props?.$width || 48,
    $height: props?.$height || 48
  }
})`
  display: grid;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  width: ${(props) => `${props.$width}px`};
  height: ${(props) => `${props.$height}px`};
  background: ${(props) => `#${props.$hexColor}`};
  border-radius: 360px;
  opacity: 0.5;
`

// export const NextImage = ({ src, width, height, ...rest }) => {
//   return <Image {...rest} src={src || 'https://erp.mju.ac.th/images/untitled.png'} width={width} height={height} />
// }

// //When moving all images to gcs this will be use.
// export const NextImageRounded = styled(NextImage)`
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   box-shadow: 0px 2px 4px ${(props: PropsTheme) => props.theme.shadowColor.veryLight};
// `

// export const NextImageWithRadius = styled(NextImage)`
//   width: ${(props: IImageWithRadius) => `${props.width}px` || 'auto'};
//   height: ${(props: IImageWithRadius) => `${props.height}px` || 'auto'};
//   box-shadow: 0px 2px 4px ${(props: IImageWithRadius) => props.theme.shadowColor.veryLight};
//   border-radius: 8px;
// `
