import { Col, message, Row, Select } from 'antd'
import { Grid } from 'antd'
const { useBreakpoint } = Grid
import React, { useContext } from 'react'
import { CancelButton, ConfirmButton } from 'components/Button'
import { StyledFormItem } from 'components/Form'
import { RadioStyled } from 'components/Input/radio'
import { dropdownStyle } from 'components/Input/select'
import { INPUT_TYPE, TextFieldWrapper } from 'components/Input/text'
import { StyledModal } from 'components/Modal'
import { OldRow } from 'components/Row'
import { HeaderMd, TextSmMinus } from 'components/Typography'
import OrderSourceRefSelect from 'containers/Order/shared/components/OrderSourceRefSelect'
import { UPSERT_PRODUCT_PRICING_INFO } from 'graphql-generator'
import { Controller, useForm } from 'react-hook-form'
import { ThemeContext } from 'styled-components'
import { DarkTheme, LightTheme } from 'theme'
import { MarketplaceSource } from 'utils/constants'
import { formatNumber } from 'utils/format'

import { useMutation } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'

import { PricingInfoWithSourceType } from '../ProductPricing'

import { initialProductPricingInfoFormValues } from './yup/initialformValues'
import { productPricingInfoUpdateSchema } from './yup/schema'
import { type OrderSource, type UpdateProductPricingInfoFormValues, PricingInfoRefType } from './types'
import { getInitialPricingInfoFormValues } from './utils'

interface UpdateProductPricingInfoModalProps {
  open: boolean
  toggleModal(): void
  orderSources: OrderSource[]
  productSku: string
  refetchProduct?(): void
  oldPricing?: PricingInfoWithSourceType
  oldSorceRefs?: string[]
}

const UpdateProductPricingInfoModal: React.FC<UpdateProductPricingInfoModalProps> = ({
  open,
  toggleModal,
  orderSources,
  productSku,
  refetchProduct,
  oldPricing,
  oldSorceRefs
}) => {
  /** ---------------------------------------- Hooks ---------------------------------------- */
  const { xs, sm, md, lg } = useBreakpoint()
  const theme = useContext(ThemeContext) as LightTheme | DarkTheme

  const {
    handleSubmit,
    control,
    setValue: setFormValue,
    formState: { errors },
    watch: watchFormValues,
    reset: resetForm
  } = useForm<UpdateProductPricingInfoFormValues>({
    resolver: yupResolver(productPricingInfoUpdateSchema) as any,
    defaultValues: {
      ...getInitialPricingInfoFormValues(initialProductPricingInfoFormValues, orderSources, oldPricing),
      productSku
    },
    mode: 'onBlur' //validate onBlur
  })

  /** ---------------------------------------- Graphql ---------------------------------------- */
  const [upsertProductPricingInfo, { loading }] = useMutation(UPSERT_PRODUCT_PRICING_INFO, {
    onCompleted: () => {
      message.success('product pricing info is successfully updated!')
      toggleModal()
      resetForm()
      refetchProduct?.()
    },
    onError: () => {
      message.error('Fail to update product pricing info!')
    }
  })

  /** ---------------------------------------- Logics ---------------------------------------- */

  //handle in submit form
  const handleOnSubmit = (values: UpdateProductPricingInfoFormValues) => {
    const { sourceType, refType, ...payload } = values
    delete payload.productSku

    if (sourceType !== MarketplaceSource.MANUAL) {
      delete payload.shippingFeePerUnit
      delete payload.codPricePerUnit
    }

    if (refType === PricingInfoRefType.Source) {
      delete payload.sourceRef
    } else {
      delete payload.source
    }

    upsertProductPricingInfo({
      variables: {
        sku: values.productSku,
        input: payload
      }
    })
  }

  //handle on cancel update product pricing
  const handleOnCancel = () => {
    resetForm()
    toggleModal()
  }

  //sourceType to handle validation
  const sourceType = watchFormValues('sourceType')
  const refType = watchFormValues('refType')

  return (
    <>
      <StyledModal
        title={<HeaderMd>{false ? 'เพิ่มราคาสินค้า' : 'แก้ไขราคาสินค้า'}</HeaderMd>}
        open={open}
        footer={null}
        onCancel={handleOnCancel}
        width={856}
        padding={(xs || sm || md) && !lg ? '40px 24px' : '40px 64px'}
      >
        <form>
          <OldRow gutter={(xs || sm || md) && !lg ? [24, 24] : [40, 24]} align="middle">
            <Col span={24}>
              <Controller
                name="refType"
                control={control}
                render={({ field }) => (
                  <>
                    <StyledFormItem
                      validateStatus={errors?.source ? 'error' : 'success'}
                      help={errors?.source && errors?.source?.message}
                    >
                      <RadioStyled.Group {...field} disabled={!!oldPricing}>
                        <RadioStyled value={'source'}>แหล่งขาย</RadioStyled>
                        <RadioStyled value={'sourceRef'}>ช่องทางขาย</RadioStyled>
                      </RadioStyled.Group>
                    </StyledFormItem>
                  </>
                )}
              />
            </Col>

            {/* -------------------- Source -------------------- */}
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              {refType === PricingInfoRefType.Source && (
                <Controller
                  name="source"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextSmMinus>ช่องทางขาย</TextSmMinus>
                      <StyledFormItem
                        validateStatus={errors?.source ? 'error' : 'success'}
                        help={errors?.source && errors?.source?.message}
                      >
                        <Select
                          {...field}
                          showSearch
                          placeholder="เลือกช่องทางขาย"
                          onChange={(value: string) => {
                            setFormValue('source', value)
                            /** sourceType field is hidden field to handle validation */
                            const { sourceType } = orderSources.find((each) => each?.name === value)
                            setFormValue('sourceType', sourceType)
                          }}
                          dropdownStyle={dropdownStyle}
                          options={orderSources?.map(({ name }) => ({
                            label: name,
                            value: name
                          }))}
                          size="large"
                          status={!!errors?.source && 'error'}
                          disabled={!!oldPricing}
                        />
                      </StyledFormItem>
                    </>
                  )}
                />
              )}

              {refType === PricingInfoRefType.SourceRef && (
                <Controller
                  name="sourceRef"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextSmMinus>ช่องทางขาย</TextSmMinus>
                      <StyledFormItem
                        validateStatus={errors?.sourceRef ? 'error' : 'success'}
                        help={errors?.sourceRef?.message}
                      >
                        <OrderSourceRefSelect
                          {...field}
                          status={!!errors?.sourceRef && 'error'}
                          disabled={!!oldPricing}
                          size="large"
                          onSelect={(_, option) => {
                            setFormValue('sourceType', option.sourceType)
                          }}
                          disabledOptions={oldSorceRefs}
                        />
                      </StyledFormItem>
                    </>
                  )}
                />
              )}
            </Col>

            {/* -------------------- Minium quantity -------------------- */}
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Controller
                name="minAmount"
                control={control}
                render={({ field }) => (
                  <>
                    <TextSmMinus>จำนวนสินค้าขั้นต่ำ</TextSmMinus>
                    <StyledFormItem
                      validateStatus={errors?.minAmount ? 'error' : 'success'}
                      help={errors?.minAmount && errors?.minAmount?.message}
                    >
                      <TextFieldWrapper
                        {...field}
                        isNumber
                        inputType={INPUT_TYPE.INPUT_STYLED}
                        status={!!errors?.minAmount && 'error'}
                        setFieldValue={setFormValue}
                        placeholder="1"
                      />
                    </StyledFormItem>
                  </>
                )}
              />
            </Col>

            {/* -------------------- Price per unit -------------------- */}
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Controller
                name="pricePerUnit"
                control={control}
                render={({ field }) => (
                  <>
                    <TextSmMinus>ราคาต่อหน่วย</TextSmMinus>
                    <StyledFormItem
                      validateStatus={errors?.pricePerUnit ? 'error' : 'success'}
                      help={errors?.pricePerUnit && errors?.pricePerUnit?.message}
                    >
                      <TextFieldWrapper
                        {...field}
                        isNumber
                        inputType={INPUT_TYPE.INPUT_WITH_ADDON}
                        placeholder="0.00"
                        addonAfter="บาท"
                        setFieldValue={setFormValue}
                        value={field?.value !== null ? formatNumber(field?.value) : ''}
                        status={!!errors?.pricePerUnit && 'error'}
                      />
                    </StyledFormItem>
                  </>
                )}
              />
            </Col>

            {/* -------------------- Shipping Fee -------------------- */}
            {sourceType === MarketplaceSource.MANUAL && (
              <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Controller
                  name="shippingFeePerUnit"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextSmMinus>ค่าจัดส่งต่อหน่วย</TextSmMinus>
                      <StyledFormItem
                        validateStatus={errors?.shippingFeePerUnit ? 'error' : 'success'}
                        help={errors?.shippingFeePerUnit && errors?.shippingFeePerUnit?.message}
                      >
                        <TextFieldWrapper
                          {...field}
                          isNumber
                          inputType={INPUT_TYPE.INPUT_WITH_ADDON}
                          placeholder="0.00"
                          addonAfter="บาท"
                          setFieldValue={setFormValue}
                          value={field?.value !== null ? formatNumber(field?.value) : ''}
                          status={!!errors?.shippingFeePerUnit && 'error'}
                        />
                      </StyledFormItem>
                    </>
                  )}
                />
              </Col>
            )}

            {/* -------------------- COD Price -------------------- */}
            {sourceType === MarketplaceSource.MANUAL && (
              <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Controller
                  name="codPricePerUnit"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextSmMinus>COD ต่อหน่วย</TextSmMinus>
                      <StyledFormItem
                        validateStatus={errors?.codPricePerUnit ? 'error' : 'success'}
                        help={errors?.codPricePerUnit && errors?.codPricePerUnit?.message}
                      >
                        <TextFieldWrapper
                          {...field}
                          isNumber
                          inputType={INPUT_TYPE.INPUT_WITH_ADDON}
                          placeholder="0.00"
                          addonAfter="บาท"
                          setFieldValue={setFormValue}
                          value={field?.value !== null ? formatNumber(field?.value) : ''}
                          status={!!errors?.codPricePerUnit && 'error'}
                        />
                      </StyledFormItem>
                    </>
                  )}
                />
              </Col>
            )}

            {/* -------------------- Note -------------------- */}
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <>
                    <div className="flex">
                      <TextSmMinus>หมายเหตุ</TextSmMinus>
                      <TextSmMinus color={theme.fontColor.light}>(ไม่บังคับ)</TextSmMinus>
                    </div>
                    <TextFieldWrapper {...field} setFieldValue={setFormValue} allowClear />
                  </>
                )}
              />
            </Col>
          </OldRow>

          {/* Action Buttons */}
          <Row gutter={16} justify={(xs || sm || md) && !lg ? 'center' : 'end'}>
            <Col>
              <CancelButton onClick={handleOnCancel} loading={loading}>
                ยกเลิก
              </CancelButton>
            </Col>
            <Col>
              <ConfirmButton onClick={handleSubmit(handleOnSubmit)} loading={loading}>
                บันทึก
              </ConfirmButton>
            </Col>
          </Row>
        </form>
      </StyledModal>
    </>
  )
}

export default UpdateProductPricingInfoModal
