import React from 'react'
import { Tag } from 'antd'
import styled from 'styled-components'
import { MarketplaceSource, OrderStatus } from 'utils/constants'
import { stringToColorCode } from 'utils/string'

const StyledTag = styled(Tag)`
  margin-right: 0px;
`

interface OrderStatusTagProps {
  status: OrderStatus | string
  style?: any
}

export const OrderStatusTag: React.FC<OrderStatusTagProps> = ({ status, style }) => {
  switch (status) {
    case OrderStatus.OPEN:
    case OrderStatus.READY_TO_SHIP:
      return (
        <Tag color="lime" style={{ userSelect: 'none', ...style }}>
          ready
        </Tag>
      )
    case OrderStatus.PICKED:
      return (
        <Tag color="pink" style={{ userSelect: 'none', ...style }}>
          {status}
        </Tag>
      )
    case OrderStatus.PRINTED:
      return (
        <Tag color="blue" style={{ userSelect: 'none', ...style }}>
          {status}
        </Tag>
      )
    case OrderStatus.PACKED:
      return (
        <Tag color="green" style={{ userSelect: 'none', ...style }}>
          {status}
        </Tag>
      )
    case OrderStatus.DOUBTED:
      return (
        <Tag color="volcano" style={{ userSelect: 'none', ...style }}>
          {status}
        </Tag>
      )
    case OrderStatus.CANCELLED:
      return (
        <Tag color="red" style={{ userSelect: 'none', ...style }}>
          {status}
        </Tag>
      )
    case OrderStatus.BOARDED:
      return (
        <Tag color="gold" style={{ userSelect: 'none', ...style }}>
          {status}
        </Tag>
      )
    case OrderStatus.TRANSPORTED:
      return (
        <Tag color="yellow" style={{ userSelect: 'none', ...style }}>
          {status}
        </Tag>
      )
    case OrderStatus.DELIVERED:
      return (
        <Tag color="green" style={{ userSelect: 'none', ...style }}>
          {status}
        </Tag>
      )
    case OrderStatus.WAIT_RETURN_TRANSPORTED:
    case OrderStatus.WAIT_RETURN_CUSTOMER:
      return (
        <Tag color="magenta" style={{ userSelect: 'none', ...style }}>
          {status}
        </Tag>
      )
    case OrderStatus.RETURNED:
    case OrderStatus.RETURNED_BY_DELIVERY_FIRM:
      return (
        <Tag color="purple" style={{ userSelect: 'none', ...style }}>
          {status}
        </Tag>
      )
    default:
      return <Tag style={{ userSelect: 'none', ...style }}>{status?.toUpperCase()}</Tag>
  }
}

interface MarketplaceSourceTagProps {
  source: MarketplaceSource | string
  style?: any
  dynamicTag?: boolean
  sourceType?: string
  trim?: boolean
  color?: string
}

export const MarketplaceSourceTag: React.FC<MarketplaceSourceTagProps> = React.memo(
  ({ source, style, dynamicTag, sourceType = '', trim = false, ...props }) => {
    switch (true) {
      case source?.includes(MarketplaceSource.SHOPEE) || sourceType?.includes(MarketplaceSource.SHOPEE):
        return (
          <StyledTag color="orange" style={{ userSelect: 'none', ...style }}>
            {`SP-${(trim && source?.toUpperCase().substring(0, 8)) || source?.toUpperCase()}`}
          </StyledTag>
        )
      case source?.includes(MarketplaceSource.LAZADA) || sourceType?.includes(MarketplaceSource.LAZADA):
        return (
          <StyledTag color="purple" style={{ userSelect: 'none', ...style }}>
            {`LZ-${(trim && source?.toUpperCase().substring(0, 8)) || source?.toUpperCase()}`}
          </StyledTag>
        )
      case source?.includes(MarketplaceSource.LNWSHOP) || sourceType?.includes(MarketplaceSource.LNWSHOP):
        return (
          <StyledTag color="#81C92F" style={{ userSelect: 'none', ...style }}>
            {`LN-${(trim && source?.toUpperCase().substring(0, 8)) || source?.toUpperCase()}`}
          </StyledTag>
        )
      case source?.includes(MarketplaceSource.MANUAL) && sourceType?.includes(MarketplaceSource.MANUAL):
        return (
          <StyledTag color="blue" style={{ userSelect: 'none', ...style }}>
            {source?.toUpperCase()}
          </StyledTag>
        )
      case source?.includes(MarketplaceSource.TIKTOK) && sourceType?.includes(MarketplaceSource.TIKTOK):
        return (
          <StyledTag color="#1b2323" style={{ userSelect: 'none', ...style }}>
            {`TK-${(trim && source?.toUpperCase().substring(0, 8)) || source?.toUpperCase()}`}
          </StyledTag>
        )
      default:
        return (
          <StyledTag
            color={dynamicTag ? stringToColorCode(source) : ''}
            style={{ userSelect: 'none', ...style }}
            {...props}
          >
            {source?.toUpperCase()}
          </StyledTag>
        )
    }
  }
)

export const PackageTypeTag = styled(Tag)``

MarketplaceSourceTag.displayName = 'MarketplaceSourceTag'
