import React, { useContext } from 'react'
import { Col, Grid } from 'antd'
import { BaseCard } from 'components/BaseCard'
import { StyledDivider } from 'components/Divider'
import { InputWithAddon, TextareaStyled } from 'components/Input/text'
import { OldRow } from 'components/Row'
import { TextMd, TextSm, TextSmMinus } from 'components/Typography'
import { UploadImage } from 'components/UploadImage'
import { FastField, FieldProps, FormikErrors, FormikTouched } from 'formik'
import { ThemeContext } from 'styled-components'
import { DarkTheme, LightTheme } from 'theme'
import { TypeImage } from 'utils/constants'
import { getErrorMessageByKey, getValidateStatusByKey } from 'utils/validate'

import { CloseOutlined } from '@ant-design/icons'

import { StyledFormItem } from '..'

const { useBreakpoint } = Grid

const uploadInfo = [
  TypeImage.COVER,
  TypeImage.NORMAL,
  TypeImage.NORMAL,
  TypeImage.NORMAL,
  TypeImage.NORMAL,
  TypeImage.NORMAL,
  TypeImage.NORMAL,
  TypeImage.NORMAL,
  TypeImage.NORMAL,
  TypeImage.NORMAL
]

interface Props {
  errors: FormikErrors<any>
  touched: FormikTouched<any>
  getLabelByType: (type: string, field: string) => JSX.Element
}

export const ProductAdditionalForm: React.FC<Props> = ({ errors, touched, getLabelByType }) => {
  const { xs, sm, md, lg } = useBreakpoint()
  const themeContext = useContext(ThemeContext) as LightTheme | DarkTheme

  return (
    <section id="product-additional-form">
      <OldRow gutter={[0, 40]}>
        <Col span="24">
          <BaseCard
            title="ข้อมูลเพิ่มเติม"
            paddingAdj={(xs || sm || md) && !lg ? '20px 24px 40px 24px' : '20px 40px 40px 40px'}
          >
            <OldRow gutter={[40, 24]} align="middle" justify="center">
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <OldRow>
                  <Col flex="auto">
                    <TextSmMinus>คำอธิบายสินค้า</TextSmMinus>
                  </Col>
                  <Col>
                    <TextSmMinus color={themeContext.fontColor.light}>(ไม่บังคับ)</TextSmMinus>
                  </Col>
                </OldRow>
                <FastField name="description">
                  {({ field }) => {
                    return (
                      <StyledFormItem
                        validateStatus={getValidateStatusByKey(errors, touched, ['description'])}
                        help={getErrorMessageByKey(errors, touched, ['description'])}
                      >
                        <TextareaStyled
                          rows={10}
                          {...field}
                          onChange={(e) => (e.target.value.length > 500 ? false : field.onChange(e))}
                        />
                        <TextSm style={{ textAlign: 'right' }} color={themeContext.fontColor.light}>
                          {`${field.value?.length | 0}/500`}
                        </TextSm>
                      </StyledFormItem>
                    )
                  }}
                </FastField>
              </Col>
            </OldRow>
            <OldRow id="images" align="middle" justify="center">
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <TextSm style={{ marginBottom: '16px' }}>รูปภาพสินค้า :</TextSm>
                <FastField name="images">
                  {({ field, form }: FieldProps) => {
                    const handleChange = (fileList) => {
                      form.setFieldValue('images', fileList)
                    }

                    return (
                      <>
                        <UploadImage
                          listType="picture-card"
                          handleChange={handleChange}
                          uploaderInfo={uploadInfo}
                          images={field.value}
                        />
                        {form.errors.images && (
                          <TextSmMinus color={themeContext.colors.danger}>
                            {getErrorMessageByKey(errors, touched, ['images'])}
                          </TextSmMinus>
                        )}
                      </>
                    )
                  }}
                </FastField>
              </Col>
            </OldRow>

            <OldRow gutter={[0, 16]} align="middle" justify="center">
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <StyledDivider />
                <TextMd>ขนาดพัสดุ :</TextMd>
              </Col>
            </OldRow>

            {(xs || sm || md) && !lg ? (
              <OldRow id="images" align="middle" justify="center">
                <Col span={24}>
                  <OldRow gutter={[40, 15]} align="middle">
                    <Col xs={24} sm={12} md={12}>
                      <OldRow>
                        <Col flex="auto">
                          <TextSmMinus>น้ำหนัก</TextSmMinus>
                        </Col>
                        <Col>
                          <TextSmMinus color={themeContext.fontColor.light}>(ไม่บังคับ)</TextSmMinus>
                        </Col>
                      </OldRow>
                      <FastField name="weight">
                        {({ field }) => {
                          return (
                            <StyledFormItem
                              validateStatus={getValidateStatusByKey(errors, touched, ['weight'])}
                              help={getErrorMessageByKey(errors, touched, ['weight'])}
                            >
                              <InputWithAddon type="number" step="any" placeholder="0" addonAfter="kg" {...field} />
                            </StyledFormItem>
                          )
                        }}
                      </FastField>
                    </Col>
                  </OldRow>
                </Col>
              </OldRow>
            ) : (
              <OldRow id="images" align="middle" justify="center">
                <Col span={24}>
                  <OldRow gutter={[24, 15]}>
                    <Col>
                      <OldRow>
                        <Col flex="auto">
                          <TextSmMinus>น้ำหนัก :</TextSmMinus>
                          <TextSmMinus color={themeContext.fontColor.light}>(ไม่บังคับ)</TextSmMinus>
                        </Col>
                      </OldRow>
                    </Col>
                    <Col>
                      <FastField name="weight">
                        {({ field }) => {
                          return (
                            <StyledFormItem
                              validateStatus={getValidateStatusByKey(errors, touched, ['weight'])}
                              help={getErrorMessageByKey(errors, touched, ['weight'])}
                            >
                              <InputWithAddon type="number" step="any" placeholder="0" addonAfter="kg" {...field} />
                            </StyledFormItem>
                          )
                        }}
                      </FastField>
                    </Col>
                  </OldRow>
                </Col>
              </OldRow>
            )}

            {/* /* -------------------------------- Dimension -------------------------------  */}
            {(xs || sm || md) && !lg ? (
              <>
                <OldRow id="images" align="middle" justify="start">
                  <Col span={24}>
                    <OldRow gutter={[24, 0]}>
                      <Col>
                        <OldRow>
                          <Col flex="auto">
                            <TextMd>กว้าง :</TextMd>
                          </Col>
                        </OldRow>
                      </Col>
                      <Col>
                        <FastField name="width">
                          {({ field }) => {
                            return (
                              <StyledFormItem
                                validateStatus={getValidateStatusByKey(errors, touched, ['width'])}
                                help={getErrorMessageByKey(errors, touched, ['width'])}
                              >
                                <InputWithAddon type="number" step="any" placeholder="0" addonAfter="cm" {...field} />
                              </StyledFormItem>
                            )
                          }}
                        </FastField>
                      </Col>
                    </OldRow>
                  </Col>

                  <Col span={24}>
                    <OldRow gutter={[24, 0]}>
                      <Col>
                        <OldRow>
                          <Col flex="auto">
                            <TextMd>ยาว :</TextMd>
                          </Col>
                        </OldRow>
                      </Col>
                      <Col>
                        <FastField name="length">
                          {({ field }) => {
                            return (
                              <StyledFormItem
                                validateStatus={getValidateStatusByKey(errors, touched, ['length'])}
                                help={getErrorMessageByKey(errors, touched, ['length'])}
                              >
                                <InputWithAddon type="number" step="any" placeholder="0" addonAfter="cm" {...field} />
                              </StyledFormItem>
                            )
                          }}
                        </FastField>
                      </Col>
                    </OldRow>
                  </Col>

                  <Col span={24}>
                    <OldRow gutter={[24, 0]}>
                      <Col>
                        <OldRow>
                          <Col flex="auto">
                            <TextMd>สูง :</TextMd>
                          </Col>
                        </OldRow>
                      </Col>
                      <Col>
                        <FastField name="height">
                          {({ field }) => {
                            return (
                              <StyledFormItem
                                validateStatus={getValidateStatusByKey(errors, touched, ['height'])}
                                help={getErrorMessageByKey(errors, touched, ['height'])}
                              >
                                <InputWithAddon type="number" step="any" placeholder="0" addonAfter="cm" {...field} />
                              </StyledFormItem>
                            )
                          }}
                        </FastField>
                      </Col>
                    </OldRow>
                  </Col>
                </OldRow>
              </>
            ) : (
              // /* ---------------------------------- WxHxL --------------------------------- */

              <>
                <OldRow id="images" align="middle" justify="start">
                  <Col span={6}>
                    <OldRow gutter={[24, 0]}>
                      <Col>
                        <OldRow>
                          <Col flex="auto">
                            <TextMd>กว้าง :</TextMd>
                          </Col>
                        </OldRow>
                      </Col>
                      <Col>
                        <FastField name="width">
                          {({ field }) => {
                            return (
                              <StyledFormItem
                                validateStatus={getValidateStatusByKey(errors, touched, ['width'])}
                                help={getErrorMessageByKey(errors, touched, ['width'])}
                              >
                                <InputWithAddon type="number" step="any" placeholder="0" addonAfter="cm" {...field} />
                              </StyledFormItem>
                            )
                          }}
                        </FastField>
                      </Col>
                    </OldRow>
                  </Col>
                  <Col span={1}>
                    <OldRow gutter={[1, 0]}>
                      <CloseOutlined />
                    </OldRow>
                  </Col>
                  <Col span={6}>
                    <OldRow gutter={[24, 0]}>
                      <Col>
                        <OldRow>
                          <Col flex="auto">
                            <TextMd>ยาว :</TextMd>
                          </Col>
                        </OldRow>
                      </Col>
                      <Col>
                        <FastField name="length">
                          {({ field }) => {
                            return (
                              <StyledFormItem
                                validateStatus={getValidateStatusByKey(errors, touched, ['length'])}
                                help={getErrorMessageByKey(errors, touched, ['length'])}
                              >
                                <InputWithAddon type="number" step="any" placeholder="0" addonAfter="cm" {...field} />
                              </StyledFormItem>
                            )
                          }}
                        </FastField>
                      </Col>
                    </OldRow>
                  </Col>
                  <Col span={1}>
                    <OldRow gutter={[1, 0]}>
                      <CloseOutlined />
                    </OldRow>
                  </Col>
                  <Col span={6}>
                    <OldRow gutter={[24, 0]}>
                      <Col>
                        <OldRow>
                          <Col flex="auto">
                            <TextMd>สูง :</TextMd>
                          </Col>
                        </OldRow>
                      </Col>
                      <Col>
                        <FastField name="height">
                          {({ field }) => {
                            return (
                              <StyledFormItem
                                validateStatus={getValidateStatusByKey(errors, touched, ['height'])}
                                help={getErrorMessageByKey(errors, touched, ['height'])}
                              >
                                <InputWithAddon type="number" step="any" placeholder="0" addonAfter="cm" {...field} />
                              </StyledFormItem>
                            )
                          }}
                        </FastField>
                      </Col>
                    </OldRow>
                  </Col>
                </OldRow>
              </>
            )}
          </BaseCard>
        </Col>
      </OldRow>
    </section>
  )
}
