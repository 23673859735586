export type OrderSource = {
  name: string
  sourceType: string
}

export interface UpdateProductPricingInfoFormValues {
  source?: string
  minAmount: number
  pricePerUnit: number
  shippingFeePerUnit?: number
  codPricePerUnit?: number
  note?: string
  sourceType: string
  productSku: string
  refType?: PricingInfoRefType
  sourceRef?: string
}

export enum PricingInfoRefType {
  Source = 'source',
  SourceRef = 'sourceRef'
}
