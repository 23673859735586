import { SortDirection } from 'graphql-shared'

export const sortAlphabetically = (a: string, b: string, reverse = false) => {
  if (a < b) return reverse ? 1 : -1
  if (a > b) return reverse ? -1 : 1
  return 0
}

export interface DefaultGraphQLSorter {
  field: string
  direction: SortDirection
}
