import React from 'react'
import { Col, Grid, notification, Row } from 'antd'
import { CancelButton, ConfirmButton } from 'components/Button'
import { TextFieldWrapper } from 'components/Input/text'
import { StyledModal } from 'components/Modal'
import { OldRow } from 'components/Row'
import { HeaderMd, TextSmMinus } from 'components/Typography'
import { StyledFormItem } from 'containers/Product/shared/product-form'
import { FastField, FieldProps, Form, Formik, FormikProps } from 'formik'
import {
  CREATE_ORDER_SOURCE,
  CreateOrderSource,
  CreateOrderSource_createOrderSource,
  GET_ORDER_SOURCES,
  GET_ORDER_SOURCES_META,
  GET_WAREHOUSES,
  GetOrderSources_orderSources,
  GetWarehouses
} from 'graphql-shared'
import { getErrorMessageByKey, getValidateStatusByKey } from 'utils/validate'
const { useBreakpoint } = Grid

import { MarketplaceSource } from 'utils/constants'

import { useMutation, useQuery } from '@apollo/client'

interface Props {
  open?: boolean
  setVisible?: (open: boolean) => void
  onCreate?: (orderSource: CreateOrderSource_createOrderSource) => void
  orderSources?: GetOrderSources_orderSources[]
}

export const CreateSourceRefModal: React.FC<Props> = ({ onCreate, open, setVisible, orderSources }) => {
  const { xs, sm, md, lg } = useBreakpoint()
  const [notificationApi, notificationContextHolder] = notification.useNotification()
  const { data: getWarehousesResponse, loading: loadingWarehouses } = useQuery<GetWarehouses>(GET_WAREHOUSES)
  // graphql
  const [createOrderSource, { loading: loadingCreateOrderSource }] = useMutation<CreateOrderSource>(
    CREATE_ORDER_SOURCE,

    {
      refetchQueries: [GET_ORDER_SOURCES, GET_ORDER_SOURCES_META],
      onCompleted: (data) => {
        onCreate(data?.createOrderSource)
        setVisible(false)
        notificationApi.info({ message: `สร้างช่องทางขาย ${data?.createOrderSource?.name} สำเร็จจ` })
      }
    }
  )

  return (
    <Formik
      initialValues={{ key: '', name: '' }}
      // validationSchema={orderSourceSchema(orderSources?.map((each) => each.key as string))}
      onSubmit={(values, { resetForm }) => {
        createOrderSource({
          variables: {
            input: {
              name: values?.name,
              profile: { shopName: values?.name },
              sourceType: MarketplaceSource.MANUAL,
              defaultWarehouse: getWarehousesResponse?.warehouses?.find((each) => each.name === 'default')?._id
            }
          }
        })
        resetForm()
      }}
    >
      {({ handleSubmit, resetForm, errors, touched }: FormikProps<any>) => {
        return (
          <StyledModal
            open={open}
            title={<HeaderMd>เพิ่มช่องทางขาย</HeaderMd>}
            onCancel={() => {
              resetForm()
              setVisible(false)
            }}
            footer={null}
            width={560}
            padding={(xs || sm || md) && !lg ? '40px 24px' : '40px 64px'}
          >
            <Form>
              {notificationContextHolder}
              <OldRow gutter={[40, 24]} justify="center">
                <Col xs={24} sm={18} md={18} lg={24} xl={18} xxl={18}>
                  <TextSmMinus>ชื่อช่องทางขาย</TextSmMinus>
                  <StyledFormItem
                    validateStatus={getValidateStatusByKey(errors, touched, ['name'])}
                    help={getErrorMessageByKey(errors, touched, ['name'])}
                  >
                    <FastField name="name">
                      {({ field }: FieldProps) => {
                        return <TextFieldWrapper {...field} placeholder="ตัวอย่าง : Facebook Manual" />
                      }}
                    </FastField>
                  </StyledFormItem>
                </Col>
              </OldRow>
              {/* <OldRow gutter={[40, 24]} justify="center">
                <Col xs={24} sm={18} md={18} lg={24} xl={18} xxl={18}>
                  <TextSmMinus>รหัสช่องทางขาย</TextSmMinus>
                  <StyledFormItem
                    validateStatus={getValidateStatusByKey(errors, touched, ['key'])}
                    help={getErrorMessageByKey(errors, touched, ['key'])}
                  >
                    <FastField name="key">
                      {({ field, form }: FieldProps) => {
                        return (
                          <StyledInput
                            {...field}
                            placeholder="ตัวอย่าง : facebook-manual"
                            onChange={(e) => form.setFieldValue('key', e.target.value.toLowerCase().replace(' ', '-'))}
                          />
                        )
                      }}
                    </FastField>
                  </StyledFormItem>
                </Col>
              </OldRow> */}
              <Row gutter={16} justify={(xs || sm || md) && !lg ? 'center' : 'end'}>
                <Col>
                  <CancelButton
                    onClick={() => {
                      resetForm()
                      setVisible(false)
                    }}
                  >
                    ยกเลิก
                  </CancelButton>
                </Col>
                <Col>
                  <ConfirmButton onClick={() => handleSubmit()} disabled={loadingCreateOrderSource}>
                    บันทึก
                  </ConfirmButton>
                </Col>
              </Row>
            </Form>
          </StyledModal>
        )
      }}
    </Formik>
  )
}
