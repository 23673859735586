import { MarketplaceSource } from 'utils/constants'

import { PricingInfoWithSourceType } from '../../ProductPricing'
import { OrderSource, PricingInfoRefType, UpdateProductPricingInfoFormValues } from '../types'

export const getInitialPricingInfoFormValues = (
  initialValues: UpdateProductPricingInfoFormValues,
  orderSources: OrderSource[],
  oldPricing?: PricingInfoWithSourceType
): UpdateProductPricingInfoFormValues => {
  if (!oldPricing) {
    const isManualIncluded = orderSources.some((each) => each.name === MarketplaceSource.MANUAL)
    const sourceData = !isManualIncluded ? { source: orderSources[0].name, sourceType: orderSources[0].sourceType } : {}

    return { ...initialValues, ...sourceData }
  }

  return {
    ...initialValues,
    source: oldPricing?.source,
    minAmount: oldPricing?.minAmount,
    pricePerUnit: oldPricing?.pricePerUnit,
    shippingFeePerUnit: oldPricing?.shippingFeePerUnit,
    codPricePerUnit: oldPricing?.codPricePerUnit,
    note: oldPricing?.note,
    sourceType: oldPricing?.sourceType,
    refType: oldPricing?.source ? PricingInfoRefType.Source : PricingInfoRefType.SourceRef,
    sourceRef: oldPricing?.sourceRef?._id
  }
}
