import { Col, Row, Spin } from 'antd'
import { Grid } from 'antd'
const { useBreakpoint } = Grid
import React, { useContext, useEffect, useState } from 'react'
import { ImageRounded } from 'components/Image'
import { dropdownStyle, StyledSelect } from 'components/Input/select'
import { OldRow } from 'components/Row'
import { TextMd, TextSm } from 'components/Typography'
import { GetLeanProducts_leanProducts } from 'graphql-shared'
import { useDebounce } from 'hooks/useDebounce'
import Highlighter from 'react-highlight-words'
import styled, { ThemeContext } from 'styled-components'
import { DarkTheme, LightTheme } from 'theme'
import { ProductType, ProductTypeLabel } from 'utils/constants'
import { formatNumber } from 'utils/format'

import { SearchOutlined } from '@ant-design/icons'

interface Props {
  funcQuery: any
  loadingQuery: boolean
  products: GetLeanProducts_leanProducts[]
  handleSelect: (sku: string) => void
  placeholder: string
  className?: string
}

const { Option } = StyledSelect

const SelectProducts = styled(StyledSelect)`
  &.product-search-editor {
    .ant-select-selector {
      appearance: none !important;
      border: none !important;
      box-sizing: border-box !important;
      outline: none !important;
      box-shadow: unset !important;
    }
  }

  user-select: none;

  &&& .ant-select-arrow {
    left: 0px;
    top: 50%;
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    padding: 0 16px;
  }

  && .ant-select-selection-item {
    padding: 0 24px !important;
    top: 2%;
  }

  && .ant-select-selection-search {
    padding: 0 28px !important;
    top: 8%;
  }

  && .ant-select-selection-placeholder {
    padding: 0 24px !important;
  }
`

const StyledLoading = styled(Spin)`
  position: absolute;
  right: 50%;
`

export const ProductSearch: React.FC<Props> = ({
  funcQuery,
  loadingQuery,
  products,
  handleSelect,
  placeholder,
  className
}) => {
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint()
  const themeContext = useContext(ThemeContext) as LightTheme | DarkTheme
  const [keyword, setKeyword] = useState(null)
  const [productSearch, setProductSearch] = useState([])
  const debouncedKeywordInput = useDebounce(keyword, 500)

  useEffect(() => {
    let isSubscribe = true
    if (isSubscribe) setProductSearch(products)

    return () => {
      isSubscribe = false
    }
  }, [products])

  useEffect(() => {
    let isSubscribe = true
    if (isSubscribe) {
      if (debouncedKeywordInput) funcQuery(debouncedKeywordInput)
      else setProductSearch([])
    }

    return () => {
      isSubscribe = false
    }
  }, [debouncedKeywordInput, funcQuery])

  const handleSearch = (value: string) => {
    setKeyword(value)
  }

  const handleClear = () => {
    funcQuery(null)
    setProductSearch([])
  }

  return (
    <SelectProducts
      className={className}
      showSearch
      allowClear
      suffixIcon={<SearchOutlined />}
      placeholder={placeholder}
      optionLabelProp="sku"
      dropdownStyle={dropdownStyle}
      onSelect={handleSelect}
      onSearch={handleSearch}
      onClear={handleClear}
      notFoundContent={loadingQuery ? <StyledLoading /> : <TextSm></TextSm>}
    >
      {productSearch?.map((item, index) => {
        return (
          <Option key={index} value={JSON.stringify(item)} sku={item.sku} name={item.name}>
            <OldRow gutter={16} align="middle">
              <Col>
                <div style={{ width: 48, height: 48 }}>
                  <ImageRounded src={item?.images?.[0]?.src} width={48} height={48} />
                </div>
              </Col>
              <Col flex="auto">
                {xs && !sm ? (
                  <>
                    <OldRow>
                      <Col>
                        <div
                          style={{ width: '120px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                        >
                          <TextSm color={themeContext.fontColor.dark}>
                            <Highlighter
                              searchWords={[debouncedKeywordInput]}
                              autoEscape={true}
                              textToHighlight={item.name}
                            />
                          </TextSm>
                        </div>
                      </Col>
                    </OldRow>
                    <OldRow>
                      <Col>
                        <TextSm color={themeContext.fontColor.medium}>
                          <Highlighter
                            searchWords={[debouncedKeywordInput]}
                            autoEscape={true}
                            textToHighlight={item.sku}
                          />
                        </TextSm>
                      </Col>
                    </OldRow>
                  </>
                ) : (
                  <>
                    <OldRow gutter={8}>
                      <Col>
                        <div
                          style={{ width: '320px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                        >
                          <TextSm color={themeContext.fontColor.dark}>
                            <Highlighter
                              searchWords={[debouncedKeywordInput]}
                              autoEscape={true}
                              textToHighlight={item.name}
                            />
                          </TextSm>
                        </div>
                        <Row gutter={4}>
                          <Col>
                            <TextSm color={themeContext.fontColor.medium}>
                              <Highlighter
                                searchWords={[debouncedKeywordInput]}
                                autoEscape={true}
                                textToHighlight={item.sku}
                              />
                            </TextSm>
                          </Col>
                          <Col>
                            <TextSm color={themeContext.fontColor.medium}>
                              {ProductTypeLabel[String(item.type || 'normal').toUpperCase()]}
                            </TextSm>
                          </Col>
                        </Row>
                      </Col>
                    </OldRow>
                  </>
                )}
              </Col>
              <Col style={{ marginLeft: 64 }}>
                <TextSm color={themeContext.fontColor.dark}>
                  ฿{formatNumber(item?.pricing?.originalPrice || '0.00')}
                </TextSm>
              </Col>
            </OldRow>
          </Option>
        )
      })}
    </SelectProducts>
  )
}
